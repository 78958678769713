/* ------------------------------- 메인 상단 카피영역 ------------------------------- */
.cptMainCopy{
    width:100%;

    .copyArea{
        

        h1{
            font-family:"YangPyeongM"; font-size:5rem; font-weight:400; line-height:1; color:#000; display:block; transform-origin:left; transform:scaleX(0.97);

            .c_blue{
                font-family:"YangPyeongM"; color:var(--key-blue01);
            }
        }
        i{
            margin-top:5px; font-size:1.6rem; font-style:normal; line-height:1.5; color:#444; display:block;
        }
    }
}

/* ------------------------------- 메인 비주얼 영역 ------------------------------- */
.cptMainVisual{
    width:100%;

    .visualArea{
        width:100%;

        .visualMain{
            width:100%; padding:0 20px;
            .bnBox{
                width:100%; height:100%; border-radius: 25px; box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35), inset -10px -10px 40px 0 rgba(0, 31, 230, 0.95); background:#005ce6; padding:30px; position:relative; overflow:hidden; 
                > * {position:relative; z-index:2;}
                > i {font-size:16px; color:#fff; font-weight:500; font-style: normal;}
                > h1 {font-size:38px; color:#fff; font-weight:500; font-family: "YangPyeongM"; line-height:1; margin:5px 0 10px; letter-spacing:-2.85px;}
                > ul {
                    width:100%;
                    li {
                        width:max-content; padding:9px 14px 8px; background:#014ec2; border-radius:17.5px; font-size:12px; color:#fff; font-weight:400; text-align:center; line-height:1; margin-bottom:5px;

                        &:last-of-type {margin-bottom:0;}
                    }
                }
                > .btnStartLoan {display:block; margin-top:33px; width:100%; padding:16px 0; text-align:center; font-size:16px; color:#fff; font-weight:500; background:#4b9ff9; border:2px solid #2990ff; border-radius:27.5px;}
                > img {position:absolute; bottom:-7.5rem; right:-3.5rem; z-index:1;}

                &::after {
                    content: ""; width: 51px; height: 44px; background: url(../../images/main/heart.png) center bottom no-repeat; background-size:100% 100%; position: absolute; right: 122px; bottom: 125px; z-index: 99; transform-origin: center 60px; transform: rotate(-140deg); animation: actHeart 3s infinite;
                }
            }
        }

        .visualSub{
            width:100%; overflow:hidden;

            .visualGroup{
                width:100%; height:290px; padding:20px; display:flex; flex-wrap:nowrap; box-sizing:border-box;
    
                .item{
                    width:230px; height:240px; margin-right:20px; border-radius:25px; background:#fff; box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.13); position:relative;
    
                    .bnBox{
                        width:100%; height:100%; padding:20px; position:relative; z-index:2;
    
                        .headline{
                            font-size:2rem; font-weight:500; line-height:1.3; color:#000; display:block;
                        }
                        .desc{
                            margin-top:7px; font-size:1.2rem; font-style:normal; font-weight:500; color:#666; line-height:1.5; display:block; position:relative;
                        }
                    }
                    
                    &.type01{
                        background:url(../../images/main/vsl02_bg.png) calc(100% - 20px) calc(100% - 25px) / 144px no-repeat;
                    }
                    &.type02{
                        background:url(../../images/main/vsl03_bg.png) calc(100% - 20px) calc(100% - 25px) / 184px no-repeat;
                    }
                }
            }
        }
    }
}

/* ------------------------------- 메인 배너 영역 ------------------------------- */
.cptMainBanner{
    width:100%; padding-top:10px; background:#f3f3f3;

    .bannerArea{
        width:100%; padding:30px 20px 0; background:#fff;

        .swiperCont{
            width:100%; height:auto; border-radius:20px; position:relative; overflow:hidden;

            .swiper-wrapper{
                .swiper-slide{
                    .link{
                        width:100%; padding-top:56.716%; position:relative; display:block;
                        img{
                            width:100%; height:100%; position:absolute; left:0; top:0;
                        }
                    }
                }
            }
        }
    }
}
/* ------------------------------- 메인 콘텐츠 영역 ------------------------------- */
.cptMainContbox{
    width:100%; padding:20px 20px 0 20px;

    .contArea{
        width:100%; display:flex; flex-wrap:wrap; 

        .banner01{
            width:100%; height:114px; border-radius:20px; background:#f4f5fa url(../../images/main/bn01_Bg.png) calc(100% - 20px) calc(100% - 23px) / 60px no-repeat;
            > a {
                display:block; width:100%; height:100%; padding:20px;
                .title{
                    font-size:2rem; font-weight:500; line-height:1.3; color:#000;
                }
                .desc{
                    padding-right:70px; font-size:1.2rem; font-weight:500; line-height:1.5; color:#666; opacity:0.8;
                }
            }
        }
        .banner02{
            width:100%; margin-top:30px; display:flex; flex-wrap:nowrap;
            .bnBox{
                width:50%; height:30px; font-size:1.6rem; font-weight:500; color:#000;
                > a { display:block; width:100%; height:100%; padding-left:48px; }
                &:nth-child(1){
                    background:url(../../images/main/bn02_Bg.png) 10px center / 28px no-repeat;
                }
                &:nth-child(2){
                    background:url(../../images/main/bn03_Bg.png) 10px center / 28px no-repeat;
                }
            }
        }
    }
}

/* ------------------------------- 메인 하단 배너 영역 ------------------------------- */
.cptFloating{
    width:100%; height:150px; margin-bottom:-60px; padding-top:40px; position:relative;

    .ftArea{
        width:100%; padding-top:20px; overflow:hidden; position:static; left:0; bottom:0; z-index:200;

        .swiperCont {
            width:100%; overflow:hidden; padding:25px 0 25px 20px; border-radius:15px 15px 0 0; box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.1); background:#fff;

            .swiper-slide {
                width:auto; height:40px; padding:0 14px 0 34px ; font-size:1.4rem; font-weight:500; color:#000; line-height:40px; border: solid 1px #dcdcdc; border-radius:20px; align-content:center;

                &.type01{width:40px; padding:0; font-size:0; border: solid 1px #f8f9fb; background:#f2f9ff url(../../images/main/ico_home.png) center / 18px no-repeat;
                    a{font-size:0; display:block; width:100%; height:100%;}
                }
                &.type02{border: solid 1px #f8f9fb; background:#f2f9ff url(../../images/main/ico_300.png) 10px center / 18px no-repeat;}
                &.type03{border: solid 1px #f8f9fb; background:#f2f9ff url(../../images/main/ico_apart.png) 10px center / 18px no-repeat;}
                &.type04{border: solid 1px #f8f9fb; background:#f2f9ff url(../../images/main/ico_car.png) 10px center / 18px no-repeat;}
                a{
                    font-size: 1.4rem; font-weight: 500; color: #000; line-height: 40px;
                }
            }
            
        }
    }
    &.fixed{
        .ftArea{
            position:fixed;
        }
    }
}


@keyframes actHeart {
    0% {
        transform:rotate(-40deg);
    }
  
    50% {
        transform:rotate(-60deg);
    }

    100% {
        transform:rotate(-40deg);
    }
}

/*2024.07.09 쿠키 바 추가*/
.cookieBar {
    position:fixed; bottom:0; left:0; width:100%; background:#fff; padding:3rem; box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1); z-index:99999;
    .inner {
        width:100%; margin:0 auto; display:flex; flex-flow: column; align-items: center; justify-content: space-between; gap:2rem;

        .text {
            width:100%;
            
            p {
                font-size:1.4rem; color:#444; font-weight:400; line-height:26px; letter-spacing:-0.4px;  word-break:keep-all;
                
                span {text-decoration:underline;}
            }
        }

        .buttons {
            width:100%; display:flex; flex-flow: row nowrap; align-items: center; justify-content: center; column-gap: 7px;

            > .sett {width:50%; font-size:16px; color:#000; font-weight:400; line-height:50px; text-align:center; border:1px solid #444; background:#fff; border-radius:10px;}
            > .act {width:50%; font-size:16px; color:#fff; font-weight:400; line-height:50px; text-align:center; border:1px solid var(--key-blue01); background:var(--key-blue01); border-radius:10px;}

        }

    }
}