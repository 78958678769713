
/* -------------------------------- 공통 -------------------------------- */
.hidden {width:1px;height:1px;overflow:hidden;border:0;clip:rect(1px,1px,1px,1px);clip-path:inset(50%);display:inline-block;position:absolute;}
.mt10 {margin-top:10px;}
.mt20 {margin-top:20px;}
.tBlue {color: var(--key-blue01);}
/* 버튼 */
.btn {
    padding: 0 20px; text-align: center; display: inline-block; transition: all 0.3s; width:100% !important;

    // 용도
    /*&.flow {height: 8.5rem; padding: 0 60px; border-radius: 1.5rem; line-height: 8.3rem; font-size: 2.4rem; font-weight: 500;}*/
    &.flow {min-height: 7rem; padding: 20px 30px; border-radius: 15px; line-height: 1.5; font-size: 1.8rem; font-weight: 500;}
    &.crud {height: 4.5rem; padding: 0 60px; border-radius: 1rem; line-height: 4.3rem; font-size: 1.6rem;}
    //&.option {height: 4.5rem; border-radius: 10px; font-size: 14px; font-weight: 500;}
    &.option {height: 4rem; border-radius: 10px; font-size: 1.2rem; font-weight: 500;}
    &.map {height: 5rem; padding: 0 20px; border-radius: 25px; line-height: 48px; text-align: left; font-size: 16px; font-weight: 500; color: #fff; background: #00c300 url("../../images/common/icon_map_w.svg") calc(100% - 20px) center no-repeat;}
    &.print {height: 5rem; padding: 0 20px; border-radius: 25px; border: 1px solid #707070; line-height: 48px; text-align: left; font-size: 16px; font-weight: 500; color: #000; background: #fff url("../../images/common/icon_print_k.svg") calc(100% - 20px) center no-repeat;}
    &.acting {height: 6.5rem; border-radius: 10px; font-size: 16px; font-weight: 500;}
    &.download {
        &::after {display: inline-block; content: ""; width: 28px; height: 28px; margin-left: 5px; background: url(../../images/common/ico_download_w.svg) center no-repeat; vertical-align: middle;}
    }
    &.table {
        min-height:50px; padding:14px; font-size:1.4rem; font-weight:500; line-height:1.5; border-radius:7px;
        .downTxt {min-height:24px; padding-right:29px; background:#fff url(../../images/common/icon_btn_download.svg) right center no-repeat;}
    }
    &.shooting{
        min-height:50px; padding:14px; font-size:1.4rem; font-weight:500; line-height:1.5; border-radius:10px;
    }

    // 컬러
    &.grey {border: 1px solid #ccc; background: #ccc; color: #fff;}
    &.black {border: 1px solid #969494; background: #444; color: #fff;}
    &.blue {border: 1px solid #005ce6; background: #005ce6; color: #fff;}
    &.line-b {border:1px solid var(--key-blue01); background-color:#fff; color:var(--key-blue01); }
    &.line-g {
        border: 1px solid #ccc; background: #fff; color: #000;
        &.option, &.acting {color:#000;}
        &:disabled{background: #f3f3f3; color: #ccc;}
        //&.option, &.acting {color:#444;}
    }

    // 이벤트
    &.overK:hover {border: 2px solid #444;}
}

//버튼 그룹
.comBtnGroup{
    width: 100%; margin-top:40px;
    &:first-child {margin-top:0;}
    .btnArea {
        display: flex; justify-content: center; align-items: center; column-gap: 10px;
        & + .btnArea{margin-top:10px;}
    }
    .bfInfo {
        margin-bottom: 20px; font-size: 1.4rem; color: #666; text-align: center; word-break: keep-all;
        .blue {font-weight: 500; color: #005ce6;}
    }
}

/* -------------------------------- 개별 컨텐츠 소타이틀 -------------------------------- */
.comContTit {
    //width: 100%; margin-bottom: 4rem; display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-end;
    width: 100%; margin-top:40px; display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-end;
    &:first-child {margin-top:0;}
    .contTit {width: 100%; font-size: 1.8rem; font-weight: 700; color: #000;word-break:keep-all;}
    .contTxt {
        .baseTxt {
            font-size:1.4rem; font-weight:400; color:#000; display:block;
        }
        .morePop {
            width:max-content; padding-right:21px; margin-top:7px; font-size:1.4rem; font-weight:700; color:var(--key-blue01); background:url(../../images/common/ico_more.svg) right calc(50% - 1px) no-repeat; display:block;
        }
    }
    .subDesc {width: 100%; font-size: 1.4rem; color: #999; display: inline-block; word-break:keep-all;}
    .information {margin:10px 0; color:var(--key-blue01); font-weight:bold;}
    .subList {
        margin-top: 10px;
        .listItem {
            margin-bottom: 20px; padding-left: 10px; font-size: 1.4rem; color: #000;position: relative;
            &::after {display: block; content: ""; width: 4px; height: 4px; border-radius: 50%; background: #666; position: absolute; top:8px; left: 0;}
            &:last-child {margin-bottom: 0;}
        }
    }
    &.sm{
        .contTit {font-size:1.6rem; font-weight:500;}
        .subDesc {font-size:1.4rem;}
    }
    &.center{
        text-align:center;
    }
    // .morePop {margin-top: 10px; color: #005ce6; font-size: 16px; font-weight: 500; text-decoration: underline; text-underline-position: bottom;}
    // .subList {
    //     margin-top: 20px;
    //     .listItem {
    //         margin-bottom: 20px; padding-left: 15px; font-size: 18px; color: #444;position: relative;
    //         &::after {display: block; content: ""; width: 5px; height: 5px; border-radius: 50%; background: #666; position: absolute; top:10px; left: 0;}
    //         &:last-child {margin-bottom: 0;}
    //     }
    // }
    // .rightSide {
    //     margin-left: auto;
    //     i {
    //         margin-right: 5px; font-style: normal; font-size: 14px; color: #000; display: inline-block; vertical-align: middle;
    //         &:last-child {margin-right: 0;}
    //     }
    //     img {margin: 0 5px; display: inline-block; vertical-align: middle;}
    // }
    //& + .comTable{margin-top:-20px;}
    //& + .comAccorTable{margin-top:-20px;}
    & + .comBtnGroup{margin-top:30px;}
}

// Form Common
// 콤보박스 공통
.frmCombo {
    position:relative; width:100%; padding:0 20px; max-width:418px;/*가로길이 추후 문의 필요*/ border:1px solid #ccc; border-radius:10px; box-sizing: border-box;

    &.on {
        border-radius:10px 10px 0 0;
    }
    & + input {margin-top: 20px;}

    button {
        position:relative; width:100%; height:64px; font-size:16px; color:#444; font-weight:500; line-height:64px; letter-spacing:-0.4px; text-align:left;

        .arrw {
            position:absolute; width:14px; height:8px; top:50%; right:0; background:url("../../images/common/ico_dropdown.png") top 50% right 0 no-repeat; transform: translateY(-50%); box-sizing:border-box; transition:all 0.4s;
        }

        &.on {
            .arrw {transform:translateY(-50%) rotate(180deg);}
        }

        .bankLogoCov {
            display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start;
            img {display:inline-block; margin-right:10px;}
        }
    }

    .drpdwBox {
        display:none; position:absolute; top:64px; left:-1px; width:calc(100% + 2px); background:#fff; border:1px solid #ccc; border-top:0; border-radius:0 0 10px 10px; z-index:99;

        .innerCover {
            width:calc(100% - 40px); margin:0 auto; border-top: 1px solid #ccc; padding:20px 0;
        }
        .innerBox {
            height:400px; overflow-y:scroll;

            ul {
                width:100%;
                li {
                    width:100%;

                    a {
                        display:block; background:#fff; width:100%; height:40px; font-size:16px; color:#444; font-weight:400; line-height:40px; letter-spacing:-0.4px; padding-left:10px; border-radius:5px; transition:all 0.4s;

                        &:hover {
                            background:#f2f9ff; color:#005ce6;
                        }
                    }

                    &.active {
                        a {
                            background:#f2f9ff; color:#005ce6; font-weight:500;
                        }
                    }
                }
            }
        }
    }
}

/*일반 라디오 공통*/
.frmRadio{
    display:flex; flex-flow: row nowrap; align-items: center; gap:30px;

    label {
        font-size: 1.4rem; display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; cursor:pointer;
    }
      
    [type="radio"], span {
        vertical-align: middle;
    }
      
    [type="radio"] { 
        appearance: none; border: 1px solid #dcdcdc; border-radius: 50%; width:30px; height:30px; font-size:24px; opacity:1; margin-right:10px; cursor:pointer;
    }

    [type="radio"] + p { 
        display:inline-block; vertical-align: middle; width:max-content; line-height:30px;
    }
      
    [type="radio"]:checked {
        border: 9px solid #005ce6;
    }
    
    [type="radio"]:disabled {
        background-color: lightgray; box-shadow: none; opacity: 1;
    }
    
    [type="radio"]:disabled + p {
        cursor: not-allowed; opacity:.4;
    }
}
/* 20240411 혹시 모를 백업
.frmRadio{
    display:flex; flex-flow: row nowrap; align-items: center; justify-content: center; gap:40px;

    label {
        font-size: 18px; display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; cursor:pointer;
    }
      
    [type="radio"], span {
        vertical-align: middle;
    }
      
    [type="radio"] { 
        appearance: none; border: 1px solid #dcdcdc; border-radius: 50%; width:30px; height:30px; font-size:24px; opacity:1; margin-right:10px; cursor:pointer;
    }

    [type="radio"] + p { 
        display:inline-block; vertical-align: middle; width:max-content; line-height:30px;
    }
      
    [type="radio"]:checked {
        border: 9px solid #005ce6;
    }
    
    [type="radio"]:disabled {
        background-color: lightgray; box-shadow: none; opacity: 1;
    }
    
    [type="radio"]:disabled + p {
        cursor: not-allowed; opacity:.4;
    }
}
*/

/*--------------------------------- 폼 가이드 --------------------------------*/
.cptForm {
    /*checkbox*/
    .item {
        //margin-bottom:4rem;
    }

    &:first-child {margin-top: 0;}
    & + & {margin-top: 120px;}
    .desc { display:inline-block; width:max-content; margin-top: 10px; font-size: 14px; color: #005ce6;}
    .flsTxt { 
        position:relative; padding-left: 20px; display:inline-block; width:max-content; margin-top: 10px; font-size: 14px; color: #fe5f5f; margin-right:10px;
        &::before {
            content: '!'; position:absolute; top:50%; left:0; transform:translateY(-50%); width:16px; height:16px; background:#fe5f5f; border-radius:50%; color:#fff; font-size:12px; font-weight:bold; text-align:center; line-height:16px; padding-right:1px;
        }
    }   
    .frmCheck {
        position: relative; min-height: 3rem;

        button {
            width:50px; height:18px; position:absolute; top:50%; right:0; background: url("../../images/common/ryt_arrow.svg") top 50% right 0 no-repeat; transform: translateY(-50%); box-sizing: border-box;
        }

        &.standalone {
            input {
                + label {
                    display: block; width: 30px; height: 30px; padding-left: 0;
                }
            }
        }

        input {
            position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer;

            + label {
                position: relative; padding-left: 42px; font-size: 16px; cursor:pointer;

                &::after {
                    content: ""; display: block; position: absolute; top: 50%; left: 0; width: 30px; height: 30px; background: url("../../images/common/check_off.svg") no-repeat center #dcdcdc; background-size: 14px 10px; border: 1px solid #dcdcdc; border-radius: 4px; transform: translateY(-50%); box-sizing: border-box;
                }

                > span:not(.hidden) {
                    font-size:16px; color:#444; line-height:32px; letter-spacing:-0.4px; font-weight:500;
                }

                > span.hglt {color:#005ce6;}

            }
        }
        
        /* 체크 */
        input:checked:not(:disabled){
            + label::after {
                background: url("../../images/common/check_off.svg") no-repeat center #005ce6; background-size: 14px 10px; border-color: #005ce6;
            }
        }
        
        /* 체크 disabled */
        input:checked:disabled {
            + label::after {
                background: url("../../images/common/check_off.svg") no-repeat center #dcdcdc; background-size: 14px 10px;
            }   
        }

        /* disabled */
        input:disabled {
            + label::after {
                background: url("../../images/common/check_off.svg") no-repeat center #dcdcdc; background-size: 14px 10px;
            }
        }

        /*mini checkbox*/
        &.miniChk {
            display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; column-gap:20px;
            > span.chkDiv {position:relative;}
            input {
                position: absolute; top: 0; left: 0; width: 1px; height: 1px; border: none; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer;
    
                + label {
                    position: relative; padding-left: 26px; margin-right: 30px; font-size: 16px; cursor:pointer;
                    &:last-child {margin-right: 0;}
    
                    &::after {
                        content: ""; display: block; position: absolute; top: 50%; left: 0; width: 16px; height: 24px; background: url("../../images/common/check_off.svg") no-repeat center; background-size: 16px 12px; transform: translateY(-50%); box-sizing: border-box; border:0;
                    }
    
                    > span:not(.hidden) {
                        font-size:16px; color:#444; line-height:24px; letter-spacing:-0.4px; font-weight:500;
                    }
    
                    > span.hglt {color:#005ce6;}
    
                }
            }
            
            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/ico_check_on.svg") no-repeat center; background-size: 16px 12px; border:0;
                }
            }
            
            /* 체크 disabled */
            input:checked:disabled {
                + label::after {
                    background: url("../../images/common/check_off.svg") no-repeat center; background-size: 16px 12px;
                }   
            }
    
            /* disabled */
            input:disabled {
                + label::after {
                    background: url("../../images/common/check_off.svg") no-repeat center; background-size: 16px 12px;
                }
            }
        }

        /*블랙 bg 화이트 chk*/
        &.blkChk {
            input {
                + label {
                    &::after {border-radius:50%;}
                }
            }
            
            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/chk_off.png") no-repeat center #444; background-size: 14px 10px; border-color: #444;
                }
            }
        }

        /*블루 bg 화이트 chk*/
        &.bluChk {
            input {
                + label {
                    &::after {border-radius:50%;}
                }
            }

            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/chk_off.png") no-repeat center #005ce6; background-size: 14px 10px; border-color: #005ce6;
                }
            }
        }

        &.type02 {
            input {
                position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer;

                + label {
                    position: relative; padding-left: 30px; font-size: 1.6rem; cursor:pointer; display:block; 
    
                    &::after {
                        content: ""; display: block; position: absolute; top: 50%; left: 0; width: 2rem; height: 2rem; border:0; background: url("../../images/common/chk_ty02_off.svg") no-repeat center; transform: translateY(-50%); box-sizing: border-box;
                    }
    
                    > span:not(.hidden) {
                        font-size:16px; color:#444; line-height:32px; letter-spacing:-0.4px; font-weight:500;
                    }
    
                    > span.hglt {color:#005ce6;}
                    
                    /*2024.05.27 추가*/
                    span.flag {
                        display:inline-block; vertical-align:middle; 
                        font-size:12px; color:#fff; font-weight:500; line-height:1; text-align:Center; padding:6px 10px; margin-left:5px; border-radius:15px;
                        &.type01 {background:#00b7fb}
                        &.type02 {background:#01d186}
                        &.type03 {background:#fdc000}
                        &.type04 {background:#fc9448}
                        &.type05 {background:#fe5f5f}
                    }
                    
                    &.terms {
                        width:90%;
                    }
                }
            }

            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/chk_ty02_on.svg") no-repeat center;
                }
            }
            
            /* 체크 disabled */
            input:checked:disabled {
                + label::after {
                    background: url("../../images/common/chk_ty02_off.svg") no-repeat center;
                }   
            }

            /* disabled */
            input:disabled {
                + label::after {
                    background: url("../../images/common/chk_ty02_off.svg") no-repeat center;
                }
            }
        }
    }

    /*radio type*/
    /*
    .frmRdo {
        position:relative; display:inline-block; vertical-align:middle; margin-right:1rem;

        input {
            position:absolute; top:0; left:0; width:100%; height:100%; border:0; appearance:none; -webkit-appearance: none; cursor:pointer; padding:0;

            + label {
                width:100%; display:flex; justify-content: center; align-items: center; position:relative; height:4.5rem; border:1px solid #ccc; border-radius:1rem; color:#444; font-size:1.6rem; text-align:center; box-sizing:border-box; line-height:1.5rem; padding: 0 5rem; cursor:pointer; font-weight:400;
            }

            &:checked {
                + label {
                    border:.2rem solid #005ce6; color:#005ce6; font-weight:500; padding: 0 4.9rem;
                }
            }
        }

        &:last-of-type {margin-right:0;}

        &.lg {
            input {
                + label {
                    height:6.4rem; line-height:6.4rem;
                }
            }
        }
    }
    */

    /*input type text*/
    .frmIpt {
        position:relative;

        .grpCov {position:relative; width:100%;}
        .dataTxt {width: 100%; font-size: 18px; font-weight: 500; color: #000;}
        .btnWrap {
            position: absolute; right: 0; bottom: 15px;
            .iptBtn {
                height: 40px; padding: 0 20px; margin-left: 2px; border-radius: 7px; line-height: 39px; font-size: 14px; color: #fff; display: inline-block;
                &.blue {background: #005ce6;}
                &.black {background: #444;}
            }
        }
        
        &.iptFlx {
            display:flex; flex-flow:row nowrap; align-items:flex-start; justify-content: flex-start; position: relative; flex-wrap: wrap;

            & > span {display:inline-block; width:120px; font-size:14px; color:#444; font-weight:bold; line-height:20px; letter-spacing:-0.35px; margin-right:40px; padding-top: 4px;}
            & > input {width:calc(100% - 160px);padding:0 0 16px 0; }
        }

        input {
            //width:100%; font-size:18px; color:#000; font-weight:500; line-height:27px; letter-spacing: -0.53px; padding:14px 0; border:0; border-bottom: 2px solid #dcdcdc;

            //&::-webkit-input-placeholder {font-size:18px; color:#ccc; font-weight:500; line-height:27px; letter-spacing:-0.45px;}
            //&:focus {outline:0; border-bottom:2px solid #005ce6;}
        }

        /*overlay type*/
        &.ovrTxt {
           .meas {font-size:2rem; color:#000; font-weight:500; line-height:28px; letter-spacing: -0.45px; position:absolute; top:0; right:0;}
        }

        /*overlay type (long word)*/
        &.lng {
            input {padding-right:30px; text-align:right;}
           .meas {width:33px;}
           .formalDataTxt {left:auto; right:30px; text-align:right;}
        }

        /*overlay type (short word)*/
        &.srt {
            input {padding-right:24px; text-align:right;}
            .meas {width:17px;}
        }

        &.wTxt {
            .iptTxt {
                display:flex; flex-flow:row nowrap; align-items:flex-start; justify-content: flex-start; margin-bottom:1.4rem; color:#999;

                .optTit {font-size:1.2rem; color:#999999; font-weight:bold; line-height:3.2rem;}
                // .required {display:inline-block; widtH:5px; height:5px; background:#005ce0; border-radius:50%;}
            }

            .desc {
                font-size:14px; color:#005ce6; line-height:32px; letter-spacing:-0.35px; font-weight:400; margin-top:10px;
            }
        }

        &.half {
            .iptCover {
                position:relative; display:flex; flex-flow:row nowrap; align-items:center; justify-content: center;

                input {
                    width:calc(50% - 20px); margin-right:20px;
                    &:last-of-type {margin-right:0; margin-left:20px;}
                }
                span.dash {display:inline-block; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); width:12px; height:2px; background:#000;}
            }
            .halfWrap {
               // width: calc(50% - 20px); margin-right: 17px; position: relative; display: inline-block; vertical-align: middle;
               // &:last-child {margin-right: 0;}
               // input {text-align: right; padding-right: 25px;}
            }
        }
        &.flx {
            display: flex; column-gap: 20px;
            .halfWrap {
                flex: 1 1 auto; display: inline-block; vertical-align: middle; position: relative;
                input[type='text'] {
                    text-align: right; padding-right: 25px;
                    &.two {padding-right: 40px;}
                }
            }
        }

        &.btnTy {
            .iptCover {
                position:relative;

                button {
                    font-size:14px; color:#fff; font-weight:400; line-height:20px; letter-spacing:-0.35px; background:#444; padding:10px 20px; box-sizing:border-box; position:absolute; bottom:15px; right:0; border-radius:7px;
                }
            }
        }

        &.spEft {
            .resetBtn {display:none; width:27px; height:27px; background:url("../../images/common/ico_reset.svg")center center no-repeat; border-radius:50%; position:absolute; top:50%; right:0; transform:translateY(-50%); }
        }
    }

    /*타이틀 영역*/
    .frmTxt {
        // margin-bottom: 10px;

        .required {
            display:inline-block; widtH:5px; height:5px; background:#005ce0; border-radius:50%; vertical-align:text-top;
        }

        &:last-child {margin-bottom: 0;}
        .title {font-size:24px; color:#000; font-weight:bold; line-height:42px; letter-spacing:-0.6px;}
        .subTitle {font-size:18px; color:#444; font-weight:400; line-height:32px; letter-spacing:-0.45px; margin-top:5px;}
        .dirTitle {
            //position:relative; display:inline-block; vertical-align:middle; padding-left:38px; font-size:18px; color:#444; line-height:32px; letter-spacing:-0.45px;
            
            &::before {
                //content: '';position:absolute; top:50%; left:0; transform:translateY(-50%); width:30px; height:30px; border-radius:50%; background:#005ce6 url("../../images/common/check_off.svg") 50% 50% no-repeat; 
            }

            + span {
                display:inline-block; vertical-align:middle; padding-left:10px; margin-left:10px; border-left:1px solid #dcdcdc; line-height:15px; font-size:16px; color:#444; font-weight:400; letter-spacing:-0.4px;
            }
        }
        .optTit {font-size:1.2rem; color:#999; font-weight:bold; line-height:3.2rem;}
    }

    /*validation*/
    /*
    .error {
        input[type='text'],
        input[type='password'] {border-bottom:2px solid #fe5f5f; color:#fe5f5f;}
        input[type='radio'] + label {border:2px solid #fe5f5f; color:#fe5f5f;}
        input[type='checkbox'] + label  > * {color:#fe5f5f !important;}
        &.frmCombo {
            border:1px solid #fe5f5f;

            button {color:#fe5f5f;}
            .drpdwBox {border:1px solid #fe5f5f; border-top:0;}
        }
        // .dash {background:#fe5f5f !important;}
        
        .optTit {color:#fe5f5f !important;}
    }
    */

    

    // datepicker
    .datepick {
        .datepickWrap {
            width: 100%;
            .tui-datepicker-input {
                width: 100%; max-width: 420px; height: 60px; border-radius: 13px; border: 1px solid #bebebe; padding: 0 64px 0 20px;
                input {border: none; border-radius: 10px; padding: 0;font-size: 16px; font-weight: 500;}
                .tui-ico-date {width: 24px; height: 24px; margin: 0; background: url(../../images/common/ico_calendar.svg) center no-repeat; right: 20px; transform: translateY(-50%);}
            }
        }
    }

    // Form 레이아웃
    // box레이아웃
    .frmBox{
        width: 100%; border: 1px solid #dcdcdc; border-radius: 15px; padding: 40px;
        &.typeOnly {padding: 30px;}
        & + .frmBox {margin-top: 10px;}
        .boxGroup {
            & > .item {
                margin-bottom: 30px;
                &:last-child {margin-bottom: 0;}
            }
        }
    }

    .imgWrap {
        margin:2.4rem 0; text-align:center;
        img {max-width:100%;}
    }

    .insertChkbox {
        padding: 0 1rem;
        
        .divTxt {
            margin:1rem 0; padding-left:3rem; width:100%; font-size:1.4rem; color:#666; font-weight:400; line-height:2.2rem; letter-spacing:-0.0035rem; word-break:keep-all;

            & + .frmCheck {padding-left:30px;}
        }

    }

    // Form 나열 리스트 레이아웃
    .frmList {
        width: 100%; margin-top: 30px;
        .listGroup {
            & > .item {
                //margin-bottom: 40px;
                &:last-child {margin-bottom: 0;}
                &.inline {
                    margin-bottom: 25px; display: flex; align-items: flex-start; justify-content: flex-start;
                    .frmTxt {width: 130px; margin: 0 30px 0 0; padding-top: 10px;}
                    .frmIpt {
                        width: calc(100% - 160px);
                        .dataTxt {padding-top: 13px;}
                    }
                }
            }
            .frmTxt {
                //margin-bottom: 14px;
                .opTit {
                    //margin-bottom: 14px;
                }
            }
            .radioWrap {}
            .frmCombo + .frmIpt {margin-top: 18px;}
        }
        & + .frmTxt {margin-top: 40px;}
    }

    // Radio 리스트 레이아웃
    .frmRdoList {
        width: 100%; margin-top:30px;
        .listGroup {
            & > .item {
                margin-bottom: 4rem;
                &:last-child {margin-bottom: 0;}

                .txt {font-size: 1.4rem; color:#000; word-break: keep-all;}
                .radioWrap {display:flex; flex-flow:row nowrap; align-items: center; justify-content: center; margin-top:2rem;}
                .radioWrap > div {width:calc(50% - .5rem);}
            }
        }
    }

    .frmBtnGroup {
       // margin-top: 20px;
        .btnArea {
            //display: flex; column-gap: 10px;
            //&.left {justify-content: flex-start;}
        }
    }

    // 여백
    .comAccorTable + & {
        margin-top:40px; padding-top:50px; position:relative;
        &::before{
            content:""; width:100vw; height:10px; background:#f3f3f3; display:block; position:absolute; left:-20px; top:0;
        }
    }
    .comBtnGroup:last-child {margin-top: 4rem;}
    .cptConfirmRadio + & {margin-top: 12rem;}
}

@media all and (max-width:370px) {
    .cptForm .frmCheck.type02 input + label {padding:0 30px;}
}

/*--------------------------------- 유의사항(추가 알림) 박스 --------------------------------*/
.comAlertBox {
    width: 100%; padding: 20px; margin: 20px 0; border-radius: 15px; background:#f3f3f3;
    //.alertTit {width: 100%; padding-left: 32px; font-size: 16px; font-weight: 700; color: #444; background: url(../../images/common/ico_notice.png) left center no-repeat;}
    .alertTxt {
        margin-top: 10px; font-size: 1.4rem; color: #444; line-height: 1.57; word-break: keep-all;
        &:first-child{
            margin-top:0;
        }
    }
}

/* -------------------------------- 테이블 -------------------------------- */
.comTable{
    width: 100%; margin-top:20px; position: relative;

    .rightText{
        width: 100%; font-size: 1.4rem; font-weight: 400; line-height: 20px; color: #000; text-align: right; position: absolute; left: 0; top: -30px;
    }
    .scrollBox {
        width:100%; overflow-x: auto;
    }
    table{
        width: 100%; table-layout: fixed; border-top: 1px solid #000; border-collapse: collapse; border-spacing: 0;

        &.free {width:auto;}
        tr{
            th{
                padding: 15px 10px; font-size: 1.4rem; text-align: center; color: #000; font-weight: 400; line-height: 1.5; 
                border-bottom: 1px solid #dcdcdc; border-right: 1px solid #dcdcdc; background: #f5f5f5;

                &:last-child {
                    border-right: 0;
                }
            }
            td{
                padding: 15px 20px; font-size: 1.4rem; text-align: center; color: #000; font-weight: 400; line-height: 1.7; 
                border-bottom: 1px solid #dcdcdc; letter-spacing: -0.4px; border-right: 1px solid #dcdcdc; background: #fff;

                &.lBdr{
                    border-left: 1px solid #dcdcdc;
                }
                &:last-child {
                    border-right: 0;
                }
            }
            .left {
                text-align: left; padding-left:20px;
            }
            &.btmBold {
                th, td {border-bottom: 1px solid #000;}
            }
        }
    }
    & + .comContTit {margin-top:40px;}
}


//------------------------------------ 아코디언 테이블 ------------------------------------
.comAccorTable {
    width: 100%; margin-top:20px;

    .scrollBox{
        width: 100%; overflow-x: auto;
    }
    .accorThead{
        border-top: 1px solid #dcdcdc; border-bottom: 1px solid #dcdcdc; display: flex; justify-content: flex-start; align-items: center;
        .td{
            height: 60px; text-align: center; line-height: 59px; font-size: 14px; font-weight:500; color: #000; background: #f5f5f5; flex: 0 0 33.3333%; position:relative;
            &::after{
                content:""; width:1px; height:30px; background:#ddd; position:absolute; top:15px; right:0;
            }
            &:last-child{
                &::after{display:none;}
            }
        }
    }
    .accorTbody{
        width: 100%;
        .accorTit{
            width: 100%; display: flex; position: relative; border-bottom: 1px solid #dcdcdc;

            &::after{
                display: block; content: ""; width: 18px; height: 10px; background: url(../../images/common/ico_table_down.svg) center no-repeat; 
                position: absolute; right: 10px; top: 25px; transition: all 0.3s;
            }
            &.nowOn{
                + .accorDt{
                    max-height: 804px;
                }
                &::after{
                    transform: rotate(180deg);
                }
            }
            .td{
                padding: 19px 0; text-align: center; font-size: 1.4rem; color: #000; flex: 0 0 33.3333%;
            }
        }
        .accorDt{
            height: auto; max-height: 0; overflow: hidden; transition: all 0.3s;
            .detail{
                background: #f2f9ff;
                .dttr{
                    border-bottom: 1px solid #bfdfff; display: flex;
                    .td{
                        padding: 14px 0; text-align: center; font-size: 14px; color: #000; flex: 0 0 33.3333%;
                    }
                }
            }
        }
    }
}


/* -------------------------------- 게시판 내 파일 다운로드 -------------------------------- */
.comDownFile{
    width:100%; margin-top:20px;
    .download{
        width:100%; margin-top:10px; padding:20px 24px; font-size:1.4rem; line-height:1.5; color:#333; border-radius:10px; background:#f7f8fc; display:block;
    }
}

/* -------------------------------- 게시판 상단 검색 등 -------------------------------- */
.comBoardTop{
    width:100%; padding:0 20px;
    .searchGroup{
        width:100%; height:60px; position:relative;
        .searchIpt{
            width:100%; padding:0 20px; font-size:16px; font-weight:400; color:#000; line-height:58px; border:1px solid #bebebe; border-radius:13px;
            &::placeholder{color:#ccc;}
        }
        .searchBtn{
            width:24px; height:24px; font-size:0; border:0; background:url(../../images/common/ico_sch.svg) center no-repeat; position:absolute; right:20px; top:18px;
        }
    }
}

/* -------------------------------- 게시판 더보기 버튼 -------------------------------- */
.comMorePage{
    width:100%; padding:0 20px; margin-top:30px;
    .btnMore{
        width:100%; height:50px; font-size:1.4rem; font-weight:500; color:#444; text-align:center; line-height:48px; border:1px solid #dcdcdc; border-radius:10px; position:relative;

        .icoDown {
            height:100%; padding-right:20px; display:inline-block; position:relative;

            &::after{ 
                content:""; width:12px; height:12px; background:url(../../images/common/ico_dep3_down.svg) right center no-repeat; display:inline-block; 
                position:absolute; right:0; top:calc(50% - 6px); transform:rotate(180deg);
            }
        }
    }
}

/* --------------------------------게시판 페이징 추가 20240510 --------------------------------*/

.comPaging {
    width:100%; position:relative; margin-top:40px;
    
    ul {
        width:100%; position:relative; display:flex; flex-flow:row nowrap; align-items: center; justify-content: center; gap:30px;

        li {
            &.pgBtn {
                > a {
                    display:block; width:30px; height:30px; border-radius:50%; background:#e5e5e5; position:relative;

                    &::before {content: ''; position:absolute; top:50%; left:50%; width:8px; height:12px; background:url(../../images/common/paging_btn.svg)50% 50% no-repeat; transform:translate(-50%,-50%);}
                }
            }

            &.prev {
                > a {
                    &::before {transform:translate(-50%,-50%) rotate(180deg);}
                }
            }

            &.notAvail {
                > a {
                    opacity:0.5; cursor:default;
                }
            }

            &.numInfo {
                display:flex; flex-flow:row nowrap; align-items: center; justify-content: flex-start; gap:5px;

                span {font-size:16px; color:#666; font-weight:500; line-height:42px; letter-spacing: -0.8px;}
            }
        }
    }
}


/* -------------------------------- 공통 버튼 -------------------------------- */
.comBtn {
    width:100%; display:flex; flex-flow:row nowrap; align-items:center; margin-top:30px;

    /*정렬*/
    &.center {
        justify-content: center;
    }
    &.left {
        justify-content: flex-start;
    }
    &.right {
        justify-content: flex-end;
    }

    /*버튼타입*/
    .showList {
        width:100%; height:50px; line-height:50px; text-align:center;  background:#005ce6; border-radius:10px; position:relative;
        .ico_arr{
            padding-right:20px; font-size:1.4rem; color:#fff; font-weight:500; background:url(../../images/common/ico_list_arr.svg) right center no-repeat;
        }
    }
}   

/* -------------------------------- 공통 버튼 -------------------------------- */
.comSelectList{
    .item{
        margin-top:30px;
        &:first-child{
            margin-top:0;
        }
        &:last-child{
            padding-bottom:20px;
        }
        .basic{
            font-size:1.6rem; line-height:1.5; font-weight:500; color:#444;
            &.active{
                color:var(--key-blue01);
            }
        }
    }
}

/* -------------------------------- 공통 버튼 -------------------------------- */
.comResult{
    margin-top:20px;
    .rsltBox{
        .rsltGroup{
            .item{
                width:100%; margin-top:10px; padding:30px; border:1px solid #dcdcdc; border-radius:25px;

                &:first-child{margin-top:0;}
                .tag{
                    width:100%; font-size:1.4rem; line-height:1.5; font-weight:500; color:#999; text-align:center;
                }
                .rslt{
                    width:100%; margin-top:20px;
                    dt{
                        width:100%; font-size:1.8rem; line-height:1.5; font-weight:500; color:#000; text-align:center;
                        b{
                            
                        }
                    }
                    dd{
                        width:100%; font-family:"RedHatDisplay"; font-size:2.8rem; line-height:1.5; font-weight:700; color:var(--key-blue01); letter-spacing:-0.07rem; text-align:center;
                        b{

                        }
                    }
                }
            }
        }
    }
}


/*2024.06.24 대출신청 및 기타 대출 프로세스에서 사용할 로딩이미지*/
.loanLoading {
    position:fixed; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.5); z-index:999; 

    & > div {
        position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); z-index:9999; text-align:center; width:100%;
        img {display:inline-block; position:relative; width:18rem; 
            animation: motion 0.6s linear 0s infinite alternate; top: 0; 
        }
        p {font-size:1.8rem; color:#fff; font-weight:400; line-height:2.8rem; letter-spacing:-0.45px; text-align:center; margin-top:1rem; width:100%;}
    }
}
@keyframes motion {
    0% {top: 0px;}
    100% {top: 15px;}
}