
/* ------------------------------- 상단 메뉴 영역 ------------------------------- */
.cptGnb{
    width:100%; min-height:180px; position:relative; z-index:300;

    &.float{
        .gnbArea{
            .gnbBody{
                border-bottom:1px solid #dcdcdc;
                .bodyArea{background:#fff;}
            }
        }
    }
    .gnbArea{
        width:100%; position:fixed; left:0; top:0;
        
        /* 최상단 영역 */
        .gnbTop{
            width:100%; min-height:60px; background:#4b9ff9;

            .topArea{
                width:100%; padding:10px 24px;

                i {font-size:2rem; font-style:normal; line-height:1.5; color:#fff; word-break:break-all;}
            }
        }

        /* 상단 메뉴 영역 */
        .gnbBody{
            background:#fff;
            .bodyArea{
                display:flex; flex-wrap:nowrap; align-items:flex-start; background:none;

                .logo{
                    width:140px; height:64px; padding-left:20px; display:flex; align-items: center;

                    img{width:100%;}
                }

                .menuArea{
                    width:calc(100% - 50px); position:relative;

                    &.appli {
                        width:calc(100% - 70px);
                        .menuTitle{
                            .button2Dep{
                                .text{
                                    padding-left:0;
                                    &::after{display:none;}
                                }
                            }
                        }
                    }

                    .menuTitle{
                        width:100%; height:70px; padding-left:20px; display:flex; position:relative; align-items:center; column-gap:5px;
                        .button2Dep{
                            text-align:left; display:block; width:24px; height:24px; background:url(../../images/common/ico_gnb_back.svg) center no-repeat;
                            
                        }
                        .btnDown{
                            width:max-content; height:100%; display:block; transition:all 0.4s; position:relative;
                            .text{
                                height:100%; font-size:2rem; font-weight:500; color:#000; line-height:70px; display:inline-block; position:relative;
                            }
                            &::after {content: ''; position:absolute; top:50%; right:-31px; width:21px; height:21px; background:url(../../images/common/ico_gnb_down.svg) center no-repeat; transform:rotate(0deg) translateY(-50%);}
                        }
                    }

                    &.active{
                        .dropDownMenu{
                            display:block;
                        }

                        .btnDown{
                            &::after {transform:rotate(180deg) translateY(50%);}
                        }
                    } 
                    .dropDownMenu{
                        width:100vw; height:calc(100dvh - 126px); overflow-y:auto; background:#fff; box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.07);
                        display:none; position:absolute; left:0; top:64px;

                        .depth2Group{
                            width:100%; padding:0 20px 20px 20px; 

                            .depth2Menu{
                                width:100%; border-top:1px solid #dcdcdc; positioN:relative;

                                &.add{
                                    &::after {
                                        content:""; width:12px; height:12px; background:url(../../images/common/ico_dep3_down.svg); transform:rotate(180deg); display:block; position:absolute; right:0; top:25px;
                                    }
                                    &.on{
                                        &::after{
                                            transform:rotate(0deg);
                                        }
                                        .depth3Group{
                                            display:block;
                                        }
                                        .depth2Name{
                                            .depth2Link{
                                                font-weight:500; color:#000;
                                            }
                                        }
                                    }
                                }
                                &:first-child{
                                    border-top:0;
                                }
                                .depth2Name{
                                    .depth2Link{
                                        padding:20px 0; font-size:1.6rem; font-weight:400; line-height:1.5; color:#666; display:block;
                                    }
                                }

                                .depth3Group{
                                    display:none;

                                    .depth3Menu{
                                        .depth3Name{
                                            padding-left:10px; padding-bottom:20px;
                                        }
                                        .depth3Link{
                                            font-size:1.4rem; line-height:1.5; font-weight:400; color:#000;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .appliCancel{
                    width:70px; padding-right:20px;
                    button{
                        font-size:1.4rem; font-weight:400; line-height: 70px; color:#666; border:0; background:transparent;
                    }
                }
                
                .myPage{
                    width:calc(100% - 190px); display:flex; justify-content:flex-end;

                    .link{
                        width:40px; height:64px; font-size:0; background:url(../../images/common/icon_my.svg) center no-repeat; display:block;
                    }
                }

                .allMenuBtn{
                    width:50px; height:70px; border:0; position:relative; outline:none;
                    
                    span{
                        height:2px; font-size:0; background:#444; display:block; position:absolute; right:20px; transition-duration:0.4s;

                        &:nth-child(1){
                            width:20px; top:26px;
                        }
                        &:nth-child(2){
                            width:20px; top:34px;
                        }
                        &:nth-child(3){
                            width:20px;top:42px;
                        }
                    }

                    // &.active{
                    //     span{
                    //         &:nth-child(1){
                    //             width:20px; top:34px; right:20px; transform:rotate(-45deg);
                    //         }
                    //         &:nth-child(2){
                    //             width:0; top:34px; right:30px; opacity:0;
                    //         }
                    //         &:nth-child(3){
                    //             width:20px; top:34px; right:20px; transform:rotate(45deg);
                    //         }
                    //     }
                    // }
                }

                .allMenuCov {
                    display:none; position:fixed; top:0px; left:0; width:100%; height:100%; background:#fff; padding:20px 0 ;

                    .topArea {
                        display:flex; flex-flow:row nowrap; align-items: center; justify-content: space-between; margin-bottom:20px; padding: 0 20px;
                        > .logo {padding-left:0; width:120px; height:auto !important;}
                        .btnOption {
                            display:flex; flex-flow:row nowrap; align-items: center; justify-content: flex-start; gap:20px;
                            .mypageIcon {
                                > a {display:block; width:20px; height:20px; font-size:0; background:url(../../images/common/icon_my.svg) center no-repeat;}
                            }
                            .closeIcon {
                                > a {display:block; width:20px; height:20px; font-size:0; background:url(../../images/common/allmenuCls.png) center no-repeat;}
                            }
                        }
                    }

                    .menuArea {
                        width:100%; position:relative; height:100%; overflow-y:scroll;
                        > ul {
                            > li {
                                &:first-of-type {padding-top:30px;}

                                border-bottom:10px solid #f3f3f3; padding-bottom:30px; margin-bottom:30px;
                                &:last-of-type {border-bottom:0;}
                                .menuTitle {
                                    padding:0 20px; height:auto; display:block; margin-bottom:30px;
                                    > p {font-size:18px; color:#000; font-weight:bold; line-height:1; letter-spacing:-0.45px; }
                                }
                                .menuSub {
                                    
                                    > ul {
                                        display:flex; flex-flow: column; align-items: flex-start; justify-content: flex-start; gap:30px;
                                        > li {
                                            width:100%; 
                                            h3 {
                                                font-size:16px; color:#000; font-weight:400; letter-spacing:-0.4px;
                                                > a {padding:0 20px; display:block; width:100%; height:100%;}
                                            }
                                        }
                                    }

                                    
                                    .menu3rd {
                                        h3 {
                                            a {
                                                display:block; width:100%; position:relative;
                                                &::after {
                                                    content:''; position:absolute; top:50%; right:20px; width:12px; height:12px; background: url(../../images/common/ico_btn_down.png) 50% 50% no-repeat; transform:rotate(0) translateY(-50%);
                                                }

                                                &.active {
                                                    &::after {
                                                        transform:rotate(180deg) translateY(50%);
                                                    }
                                                }
                                            }
                                        }

                                        ul {
                                            background:#f6f7f9; padding:15px 30px; margin-top:20px; display:none;
                                            li {
                                                width:100%; margin-bottom:30px;
                                                a {
                                                    font-size:14px; color:#000; font-weight:400; line-height:1; letter-spacing:-0.35px;
                                                }

                                                &:last-of-type {margin-bottom:0;}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
}
