
// ------------------------------------ 서비스 공지/에러 ------------------------------------
.cptError{
    width: 100%; margin-top: 40px;
    .errorArea {
        width: 100%; padding: 110px 20px 0 20px; background: url(../../images/common/img_logo_noti.png) center top / 60px no-repeat;
        .errTitle {
            font-size: 1.8rem; line-height: 1.5; color: #000; font-weight: 700; text-align: center;
        }
        .errMsg {
            margin-top: 20px; font-size: 1.6rem; color: #000; font-weight: 400; text-align: center;
        }
        .infoBox {
            width: 100%;
            .textBox {
                width:max-content; margin:30px auto 0;
                .text {
                    font-size: 1.4rem; color: #000; font-weight: 400; text-align: left; display: block;
                }
            }
        }
    }
}


// ------------------------------------ 로딩 ------------------------------------
.comLoading {
    width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); display: block; position: fixed; left: 0; top: 0; z-index: 1000;
    .loadArea {
        width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;
    }
    .loadBox {
        width:65px; height:65px; position:relative;
        
        .ball{
            &::before{
                content:""; width:15px; height:15px; border-radius:50%; background:#fff; display:block; transform:scale(0);
                position:absolute; left:0; top:-7.5px; transform-origin: center; animation: loading 1.6s infinite; 
            }
            width:32.5px; height:0px; background:#fff; display:block; position:absolute; left:0; top:32.5px; transform-origin: top right; 
            
            &:nth-child(1){transform: rotate(0deg);
                &::before{
                    animation-delay:0.2s;
                }
            }
            &:nth-child(2){transform: rotate(45deg);
                &::before{
                    animation-delay:0.4s;
                }
            }
            &:nth-child(3){transform: rotate(90deg);
                &::before{
                    animation-delay:0.6s;
                }
            }
            &:nth-child(4){transform: rotate(135deg);
                &::before{
                    animation-delay:0.8s;
                }
            }
            &:nth-child(5){transform: rotate(180deg);
                &::before{
                    animation-delay:1s;
                }
            }
            &:nth-child(6){transform: rotate(225deg);
                &::before{
                    animation-delay:1.2s;
                }
            }
            &:nth-child(7){transform: rotate(270deg);
                &::before{
                    animation-delay:1.4s;
                }
            }
            &:nth-child(8){transform: rotate(315deg);
                &::before{
                    animation-delay:1.6s;
                }
            }
        }
    }
}
@keyframes loading {
    0% {
        transform:scale(0);
    }

    20% {
        transform:scale(1);
    }
  
    100% {
        transform:scale(0);
    }
}

