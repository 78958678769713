.cptFooter{
    width:100%; margin-top:60px;

    .footerArea{
        width:100%;

        .footTop{
            width:100%; background:#f5f5f5;

            .topArea{
                width:100%; padding:17px 20px;

                .btmMenuGroup{
                    display:flex; flex-wrap:wrap;
                    
                    .btmMenu{
                        padding-right:16px; position:relative;

                        &::after{
                            content:""; width:1px; height:8px; background:#aaa; display:block; position:absolute; top:8px; right:7px;
                        }
                        &:last-child{
                            padding-right:0;

                            &::after{
                                display:none;
                            }
                        }
                        a{
                            font-size:1.2rem; color:#8b9297; line-height:23px;
                        }
                    }
                }
            }
        }

        .footSite{
            width:100%; height:32px; padding-left:20px; background:#f5f5f5; position:relative; z-index:100;

            .siteGroup{
                width:220px; height:32px; border-radius:16px; background:#fff; overflow:hidden; position:absolute; left:20px; top:0;

                .item{
                    .linkText{
                        padding:0 50px 0 20px; font-size:1.2rem; font-weight:500; color:#111; line-height:32px; white-space:nowrap; display:block;
                    }
                }

                &::after {
                    content:""; width:12px; height:12px; background:url(../../images/common/btn_open.svg) no-repeat; display:block; transform:rotate(0deg); position:absolute; top:10px; right:20px;
                }
                &:hover {
                    height:auto; overflow:auto;

                    &::after {
                        transform:rotate(180deg);
                    }
                    a .linkText{
                        &:hover{
                            background:rgba(0,0,0,0.05);
                        }
                    }
                }
            }
        }

        .footBody{
            width:100%; background:#f5f5f5; padding:20px;

            .bodyArea{
                width:100%; position:relative;

                .footLogo{
                    width:90px;
                    img{
                        width:100%;
                    }
                }
                .copyright{
                    margin-top:12px; font-size:1.1rem; font-weight:500; color:#444; line-height:1.5;
                }
                .footInfo{
                    margin-top:12px; font-size:0.9rem; font-weight:400; color:#444; line-height:16px;
                }
            }
        }

        .footBtm{
            width:100%; background:#f5f5f5; padding:0 20px 20px;

            .btmArea{
                width:100%; padding-top:20px; border-top:1px solid #dcdcdc;

                .text{
                    font-size:1.8rem; color:#444; line-height:1.5; word-break:keep-all;
                }
                .btmBn{
                    margin-top:10px; display:flex; flex-wrap:nowrap;
                    .img{
                        width:65px; mix-blend-mode: darken;
                        img{width:100%;}
                    }
                    .text{
                        margin-left:10px; font-size:1rem; color:#8b9297; line-height:1.5; align-self:center;
                    }
                }
            }
        }
    }
}