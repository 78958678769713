/* ------------------------------- 서브 스와이프 2뎁스 ------------------------------- */
.cptSubMenu{
    padding:10px 0 30px;

    .subMenuArea{
        padding:0 20px; overflow:hidden; position:relative;

        &::after{content:""; width:100%; height:1px; background:#dcdcdc; position:absolute; left:0; bottom:0;}

        .depth2Group{
            .depth2Menu{
                width:max-content;

                .depth2Name{
                    width:max-content;

                    .depth2Link{width:max-content; height:46px; line-height:44px; font-size:1.6rem; font-weight:400; color:#999; display:block;}
                }
                &.active{
                    .depth2Name{
                        .depth2Link{font-weight:500; color:#000; border-bottom:2px solid #000;}
                    }
                }
            }
        }
    }
}

/* ------------------------------- 서브 스와이프 3뎁스 ------------------------------- */
.cptSub3Depth{
    padding-bottom:30px;

    .subMenuArea{
        padding:0 20px; overflow:hidden; position:relative;

        .depth3Group{
            .depth3Menu{
                width:max-content;

                .depth3Name{
                    width:max-content;

                    .depth3Link{
                        width:max-content; padding:0 20px; height:40px; line-height:38px; font-size:1.2rem; font-weight:400; color:#999; 
                        border:1px solid #ddd; border-radius:20px; display:block;
                    }
                }
                &.active{
                    .depth3Name{
                        .depth3Link{color:#fff; border:1px solid #000; background:#000;}
                    }
                }
            }
        }
    }
}