
/* -------------------------------- 대출 소개페이지 -------------------------------- */
.cptLoanInfo {
    width:100%;
    .loaninfoBox {
        width: 100%; padding:0 20px;
        .loanTitArea {
            width: 100%; padding:40px 20px; border-radius: 25px; background:#f2f9ff center 186px / 200px no-repeat;
            &.baro300 {
                background-image: url("../../images/sub/loan_baro300.png");
                background-size:25rem;
            }
            &.estate {
                background-image: url("../../images/sub/loan_estate.png");
                background-size:23.5rem;
            }
            &.car {
                background-image: url("../../images/sub/loan_car.png");
                background-size:28rem;
                background-position: center 207px;
            }
            
            .loanTit {
                padding:0 10px;
                
                i {font-size: 1.4rem; font-weight: 500; color: #444; display: block; font-style: normal;}
                b {margin-top:5px; font-size: 3.6rem; color: #000; font-weight: 500; font-family: "YangPyeongM"; line-height: 1; letter-spacing: -0.075em; display: block;}
            }
            .tagGroup {
                margin: 20px 10px 0 10px; display: inline-flex; align-items: center; justify-content: center; column-gap: 5px;
                .tag {min-height: 30px; padding: 0 12px; border-radius: 15px; line-height: 28px; font-size: 1.2rem; font-weight: 400; color: #000; border:1px solid #dcdcdc; background: #f8f9fb; letter-spacing:-0.08em;}
            }
            
            .summaryGroup {
                margin-top: 220px; display: flex; align-items: center; justify-content: space-around;
                .item {
                    padding: 0 18px; position: relative;
                    &:nth-child(2) {padding-left: 21px;}
                    &:nth-child(3) {padding-left: 34px;}
    
                    dl {
                        text-align: center;
                        dt {padding-top: 40px; font-size: 1.2rem; font-weight: 500; color: #888; background: center top / 30px no-repeat;}
                        &.period {
                            &.per60 dt {background-image: url("../../images/sub/loan_ico10.png"); background-size:45px 45px;}
                            dt {background-image: url("../../images/sub/loan_ico01.png");}
                        }
                        &.max dt {background-image: url("../../images/sub/loan_ico02.png");}
                        &.step dt {background-image: url("../../images/sub/loan_ico03.png");}
                        dd {margin-top:4px; font-size: 1.4rem; font-weight: 500; color: #000;}
                    }
                }
            }
            
            .btnArea {
                width:100%; margin-top: 20px; text-align: center;
                .loanBtn {min-width: 100%; height: 65px; border-radius: 15px; font-size: 1.8rem; color: #fff; text-align: center; line-height: 65px; background: #005ce6; display: inline-block;}
            }
        }
    }
    
    .loanSumm{
        padding:10px 20px 20px;

        .needsGroup {
            display: flex; column-gap: 10px;

            .item {
                width: 50%; min-height: 149px; padding: 20px; border-radius: 15px; border: 1px solid #f3f3f3; background: #f8f9fb; display: flex; align-items: center; justify-content: flex-start; column-gap: 10px;

                .needs {
                    width:100%; height:100%; font-size: 1.4rem; font-weight: 500; color: #000; line-height:1.5; display: block; background: right bottom / 60px no-repeat;
                }
            }
        }
    }

    .loanDetail {
        border-top: 10px solid #f3f3f3;
        .dtlGroup {
            padding:0 20px;
        }

        .dtlItem {
            padding:40px 10px; border-top:1px solid #dcdcdc;

            &:first-child{
                padding-top:30px; border-top:0;
            }

            .detail {
                display: flex; flex-wrap:wrap;
                dt {
                    width: 100%; font-size: 1.8rem; font-weight: 700; color: #000;
                }
                dd {
                    width: 100%; margin-top:20px;
                    .desc {
                        font-size: 1.4rem; color: #000; line-height: 2;
                        .unit {
                            width: 100%;display: inline-block;
                        }
                        b {font-weight: 500;}
                        .tBlue {color: #005ce6;}
                    }
                    .adds {
                        margin-top: 5px;
                        .txt {
                            font-size: 1.2rem; color: #444; line-height:22px;
                        }
                    }
                }
            }

        }
    }

}

/* -------------------------------- 대출 소개 플로팅 -------------------------------- */
.cptLoanFloat {
    width: 100%; margin: 0 0 -60px 0; height: 141px; position: relative;

    .floatWrap, .floatApWrap, .floatPopWrap {
        width: 100%; position: fixed; bottom: -141px; left: 0; transition: all 0.2s; z-index: 150;
        
        &::before {display: block; content: ""; width: 100%; height: 121px; border-radius: 15px 15px 0 0; background: rgba(255,255,255,0.5); backdrop-filter: blur(3px); position: absolute; bottom: 0; left: 0; z-index: -9;}
        &.floatOn {bottom: 0;}
        &.floatNone {position: absolute; bottom: 0;
            .floatBox{
                box-shadow:none;
            }
        }

        .floatBox {
            width: 100%; height: 121px; padding: 20px 20px 36px 20px; border-radius: 15px 15px 0 0; display: flex; justify-content: space-between; align-items: center;  box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1);
        
            .btnArea {
                width: 100%; text-align: center;
                .loanBtn {width:100%; height: 65px; padding: 0 20px; border-radius: 15px; text-align: center; line-height: 65px; font-size: 1.8rem; color: #fff; background: #005ce6; display: inline-block; 
                    &.dark{
                        background:#444;
                    }
                }
            }
        }
    }

    .floatPopWrap {
        &.floatNone{
            position:fixed;
            .floatBox{
                box-shadow: 0 -5px 15px 0 rgba(255, 255, 255, 0.1);
            }
        }
    }
    
}

/* -------------------------------- 약관 및 대출 (3-1 ~ 3-3) -------------------------------- */
.cptTermsService{
    width:calc(100% - 4rem); margin:0 auto; padding:6rem 3.5rem; text-align:center; border:1px solid #dcdcdc; border-radius:2.5rem; background:#fcfcfc;

    &.noLine {border:none; border-radius:auto; background:#fff; padding:0; text-align:left;}
    .serviceArea{
        width: 100%; 
        .baseMsg{
            margin-top: 20px; font-size: 1.4rem; line-height: 2.4rem; font-weight: 400; color: #000;
            &:first-child {
                margin-top: 0;
            }
        }
        .btnArea{
            margin-top: 30px; width: 100%; display: flex;
            .serviceBtn{
                width: 100%; height: 70px; font-size: 1.8rem; font-weight: 500; color: #fff; text-align: center; line-height: 70px; border-radius: 15px; background: #005ce6; display: block;
            }
        }
        .listBtnArea{
            margin-top: 4rem;

            .item{
                width: 100%; margin-top:40px; padding: 30px 20px; border-radius: 25px; background:#f1f9ff; transition: background 0.2s; position: relative;

                &:first-child{margin-top:0;}
                .btnName{
                    width: 100%; font-size: 3rem; font-family: "YangPyeongM"; font-weight: 500; color: #000; line-height: 1; text-align: center;
                }
                .serviceBtn{
                    width: 100%; height: 55px; margin-top:20px; font-size: 16px; font-weight: 400; color: #fff; line-height: 53px; text-align: center; 
                    border: 1px solid #64bdff; border-radius: 27.5px; background: #64bdff; display: block;
                }
                &.type01 .btnName{
                    padding-bottom:157px; background:url(../../images/sub/bg_loan_300.png) center bottom / 203px no-repeat;
                }
                &.type02 .btnName{
                    padding-bottom:123px; background:url(../../images/sub/bg_loan_house.png) center bottom / 203px no-repeat;
                }
                &.type03 .btnName{
                    padding-bottom:148px; background:url(../../images/sub/bg_loan_car.png) center bottom / 132px no-repeat;
                }
            }
        }
        .comContTit{
            margin-bottom:0;
        }
    }
}

/* -------------------------------- 약관 및 정책 (3-4 ~ 3-10) -------------------------------- */
.cptTermsPrivacy{
    width: 100%; padding:0 20px;
    .privacyArea{
        width: 100%; 
        
        .effectiveDate{
            margin-top:20px; padding-bottom:50px; position:relative;

            &::after{
                content:""; width:calc(100% + 40px); height:10px; background:#f3f3f3; display:block; position:absolute; left:-20px; bottom:10px;
            }
            .text{
                font-size:1.6rem; line-height:1.5; font-weight:400; color:#000;
            }
            .date{
                margin-top:10px;
                select{
                    width:100%; height:60px; padding:0 20px; font-size:1.6rem; line-height:58px; color:#999; border:1px solid #bebebe; border-radius:13px;
                    background:url(../../images/common/ico_privacy_down.svg) calc(100% - 20px) center no-repeat;
                }
            }
        }
        .privacyPlace{
            width: 100%; margin-top: 20px;
        }
        .baseTitle{
            margin-top: 30px; font-size:1.6rem; font-weight:500; line-height:1.5; color: #000;
            &:first-child {
                margin-top: 0;
            }
        }
        .baseMsg{
            margin-top: 20px; font-size: 1.4rem; line-height: 1.5; font-weight: 400; color: #000;
            &:first-child {
                margin-top: 0;
            }
        }
        .baseList{
            margin-top: 20px;
            .baseItem{
                padding-left: 10px; font-size: 1.4rem; line-height: 1.5; font-weight: 400; color: #000; position: relative;
                &::before{
                    content: ""; width: 4px; height: 4px; background: #000; border-radius: 50%; display: block; position: absolute; left: 0; top: 0.9rem;
                }
            }
        }
        .comContTit{
            margin-bottom:0;
        }
    }
}

/* -------------------------------- 금융소비자 안내 -------------------------------- */
.cptContFinance{
    width: 100%;

    .financeArea{
        width:100%; padding:0 20px;

        .contBox{
            width: 100%; margin-top: 40px; padding: 60px 20px; border: 1px solid #dcdcdc; border-radius: 15px; background: #fcfcfc;
            &.type01{
                margin-top:20px; padding:60px 35px 237px; border: 0; border-radius: 25px; background: #f2f9ff url(../../images/sub/finance_img01.png) center bottom no-repeat; text-align:center;
            }
            .boxTit{
                margin-top: 20px; font-size: 1.8rem; line-height: 1.5; font-weight: 400; color: #000;
                &:first-child {
                    margin-top: 0px;
                }
                .keyColor {
                    font-weight: 700; color: var(--key-blue01);
                }
            }
            .boxB{
                margin-top: 20px; font-size: 1.6rem; line-height: 1.5; font-weight: 500; color: #000;
                &:first-child{
                    margin-top:0;
                }
            }
            .boxText{
                margin-top: 20px; font-size: 1.6rem; line-height: 1.5; font-weight: 400; color: #000;
                &:first-child{
                    margin-top:0;
                }
            }
            .center{
                text-align:center;
            }
            .comBtnGroup {
                margin-top: 20px;
                .btn.crud{
                    height:auto; font-size:1.4rem; padding:15px 30px; line-height:1.57;
                }
            }
        }
        .financeTitle{
            width: 100%; margin-top: 30px; font-size: 1.6rem; line-height: 1.5; font-weight: 500; color: #000;
        }
        .cardGroup{
            width: 100%; margin-top: 20px; display: block;

            .item{
                width: 100%; margin-top: 20px; padding: 31px 30px 40px 30px; border: 1px solid #ddd; border-radius: 15px; background: #fcfcfc;

                .cardCont{
                    .cardTit{
                        font-size: 2rem; font-weight: 700; line-height: 2.4rem; color: #000; width:max-content; position:relative;

                        &::before {content: ''; position:absolute; top:50%; right:-39px; width:32px; height:32px; background:url(../../images/sub/ico_card04.png)0 0 no-repeat; transform:translateY(-50%)}
                    }
                    .cardText{
                        margin-top: 13px; font-size: 1.4rem; font-weight: 400; line-height: 1.7; color: #000;
                    }
                    .ex{
                        font-size:1.2rem; font-weight:400; line-height:2; color:#999;
                    }
                }

                &:first-child{
                    margin-top:0;
                    .cardTit {
                        &::before {background:url(../../images/sub/ico_card01.png)0 0 no-repeat;}
                    }
                }

                &:nth-of-type(2) {
                    .cardTit {
                        &::before {background:url(../../images/sub/ico_card02.png)0 0 no-repeat;}
                    }
                }

                &:nth-of-type(3) {
                    .cardTit {
                        &::before {background:url(../../images/sub/ico_card03.png)0 0 no-repeat;}
                    }
                }
            }
        }
        .baseMsg{
            margin-top: 20px; font-size: 1.4rem; line-height: 1.5; font-weight: 400; color: #000;
            &.xl{
                font-size:1.6rem;
                & + .comTable {margin-top:10px;}
            }
            &:first-child {
                margin-top: 0;
            }
        }
        .baseList{
            width: 100%; margin-top: 20px;
            .item{
                margin-top: 8px; padding-left: 9px; font-size: 1.4rem; font-weight: 400; line-height: 1.5; color: #000; position: relative;
                &:first-child{
                    margin-top: 0;
                }
                &::before{
                    content: "-"; display: block; position: absolute; left: 0; top: -1px;
                }
            }
        }
        .infoTxt{
            margin-top:20px;
            p{
             padding-left:16px; text-indent:-16px; font-size:1.4rem; font-weight:400; line-height:1.71; color:#000; 
            }
            & + .infoTxt{
                margin-top:0;
            }
        }
        .imgBox{
            width:100%; margin-top:20px;
            img{
                width:100%;
            }
        }
        .comContTit{
            margin-top:40px; margin-bottom:0;
            & + .financeTitle{
                margin-top:20px;
            }
            & + .contBox{
                margin-top:20px;
            }
            &:first-child{
                margin-top:0;
            }
        }
        .comBtnGroup{
            margin-top:40px;
        }

    }
}


//신분증 촬영, 제출
.cptIdentifi{
    width:100%;
    .identifiPlace{
        width:100%; padding:0 20px;

        .identiBox{
            width:100%; margin-top:40px; padding:40px 20px; border:1px solid #92cdff; border-radius:15px; background:#f2f9ff;

            .identiTit{
                width:100%;
                dt{
                    width:100%; font-size:1.6rem; font-weight:500; color:#000; text-align:center;
                }
                dd{
                    width:100%; font-size:1.4rem; font-weight:400; color:#999; text-align:center;
                }
            }
            .fileArea{
                width:100%; margin-top:20px; display:flex; align-items: center; justify-content: center;

                .imgBox{
                    width: 245px; height: 155px; display: flex; justify-content: center; align-items: center;
    
                    img {
                        max-width: 100%; max-height: 100%;
                    }
                }
            }
            .adds{
                margin-top: 12px; padding-left: 27px; font-size: 1.2rem; color: #000; line-height: 1.5; letter-spacing: -0.45px; position: relative; 
                display: inline-block; vertical-align: middle;
                &::before {
                    content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 20px; height: 20px; border-radius: 50%; 
                    background:url(../../images/common/ico_alert.svg) 50% 50% no-repeat;
                }
            }
            .comBtnGroup {margin-top:30px;}
        }
    }
    .identifiArea{
        .imgBox{
            width:500px; max-width:100%; margin:0 auto; position:relative; border:1px solid #dcdcdc; border-radius:15px; background:#f3f3f3 url(../../images/common/ico_camera.svg) center no-repeat; overflow:hidden;
            img{
                width:100%; height:100%; display:block;
            }
        }
        .dirTitle{
            margin-top:12px; padding-left: 27px; font-size: 1.4rem; color: #000; line-height: 1.5; letter-spacing: -0.45px; position: relative; display: inline-block; vertical-align: middle; 
            &::before{
                content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 20px; height: 20px; border-radius: 50%; 
                background: #005ce6 url(../../images/common/ico_notice.svg) 50% 50% no-repeat;
            }
            & + .comBtnGroup{margin-top:40px;}
        }
        .flsTxt{
            width:100%; margin-top:20px; font-size:1.6rem; font-weight:500; line-height:1.5; color:#fe5f5f; text-align:center;
            & + .comBtnGroup{margin-top:30px;}
        }
    }
}
//------------------------------------  대출 승인 ------------------------------------ 
.cptApprove {
    .alertBox{
        width: 100%; padding: 140px 20px 0; text-align: center;
        .alertTit{
            dt{
                padding-top: 85px; font-size: 2.4rem; color: #000; font-weight: 500; background: url(../../images/common/ico_approve.svg) center top no-repeat;
            }
            dd{
                margin-top: 30px; font-size: 1.6rem; color: #666; line-height: 1.625;
            }
            &:last-child{margin-bottom: 100px;}
            & + .comBtnGroup{margin-top: 100px;}
        }
        .alertAdds{
            margin-top:10px; margin-bottom:100px;
            p{
                font-size:1.4rem; line-height:1.5; font-weight:500; color:#999;
            }
        }
        &.deny{
            .alertTit{
                dt{
                    background: url(../../images/common/ico_deny.svg) center top no-repeat;
                }
            }
            .alertAdds{
                margin-top:20px;
                p{
                    color:#fe5f5f;
                }
            }
        }
    }
    .comBtnGroup{
        padding-bottom:40px;
    }
}



//------------------------------------  간편인증(No Data) ------------------------------------ 
.cptNoneData{
    .dataArea{
        padding: 0 20px;
        .dataBox{
            width: 100%; padding: 40px 0 40px; text-align: center;

            .txtArea{
                margin-top: 20px;

                .alert{
                    font-size: 2.4rem; font-weight: 500; color: #000;
                }
                .adds{
                    margin-top: 30px; font-size: 1.6rem; color: #666;
                }
            }
        }
        .comBtnGroup{
            margin-top:30px;
        }
        .comInfoBox + .comBtnGroup {margin-top: 40px;}

    }
}

//------------------------------------ 나의대출조회 > 대출심사현황 ------------------------------------
.cptStateBox{
    width: 100%;

    .loanArea{
        width:100%; padding:0 20px;
    }

    .stateGroup{
        .item{
            width:100%; margin-top:20px;
            &:first-child{
                margin-top:0;
            }
        }
    }
    .stateBox{
        width: 100%; padding: 40px 30px; border-radius: 25px; background: #f7f8fc;

        .itemInfo {
            .name{
                font-size: 2.4rem; font-weight: 500; color: #000;
            }
            .date{
                margin-top: 7px; font-size: 1.6rem; color: #444;

                b{font-weight: 400;}
                i{margin-left:7px; font-style: normal; font-weight: 500;}
            }
        }
        .stateBar{
            margin-top:20px;

            .barWrap{
                width: 100%; height: 7px; border-radius: 3.5px; background: #fff; box-shadow: 0 3px 7px 0 rgba(68, 68, 68, 0.04); overflow: hidden;
                
                .bar{
                    height: 7px; border-radius: 3.5px; font-size: 0; background: var(--key-blue01);
                }
            }
            .stateTit {
                margin-top: 8px; display: flex; align-items: center; justify-content: flex-start;
                .state {
                    width: 25%; font-size: 14px; color: #888; font-weight: 500; text-align: right;
                    &.now {color: #000;}
                }
            }
            
            // &.deny{
            //     .barWrap{
            //         .bar{background:#fe5f5f;  }
            //     }
            // }
        }
        .stateNow{
            margin-top: 40px;

            .now{
                font-size: 1.8rem; font-weight: 500; color: #000; 
                b{font-weight:700; color:var(--key-blue01);}
            }
            .adds{
                margin-top: 10px; font-size: 1.4rem; color: #666; line-height: 1.625;
            }
            .more{
                width: 100%; margin-top: 10px; font-size: 1.4rem; color: #999; display: inline-block;
            }
            .deny{
                width: 100%; margin-top: 10px; font-size: 1.4rem; color: #fe5f5f; display: inline-block;
            }
        }
    }
    .comBtnGroup{
        margin-top:40px;
        .btn.crud{
            width:196px !important; font-size:1.4rem;
        }
    }
}

//------------------------------------ 나의대출조회 > 대출정보조회 ------------------------------------

.cptLoanDetail{
    width:100%; 
    .dtArea{
        .cardBtnGroup{
            width:100%; height:227px; padding:0 20px; overflow:hidden; position:relative;
            .cardGroup{
                .cardItem{
                    width:280px;
                    .card{
                        width:280px; padding:30px; border:1px solid #dcdcdc; border-radius:25px; background:#fff;

                        &.nowOn{
                            padding:29px; border:1px solid #bfdfff; background:#f2f9ff;
                        }
                        .tit{
                            font-size:2rem; font-weight:700; color:#000; line-height:1.6; text-align:left;
                        }
                        .infoGroup{
                            margin-top:20px; text-align:left;
                        }
                        .info{
                            margin-top:7px; font-size:1.6rem; font-weight:400; color:#444;
                            &:first-child{
                                margin-top:0;
                            }
                            b{
                                font-weight:500;
                            }
                            i{
                                font-style:normal;
                            }
                        }
                    }
                }
            }
            .swiper-pagination{
                bottom:0;
                .swiper-pagination-bullet{
                    width:6px; height:6px; margin:0 2.5px; background:#dbdbdb; border-radius:3px; transition:all 0.3s;
                    &.swiper-pagination-bullet-active{
                        width:18px; height:6px; background:#444;
                    }
                }
            }
        }
        .loanDtGroup{
            margin-top:40px; 
            .dtItem{
                display:none;
                &.on{
                    display:block;
                }
            }
            .dtBox{
                width:100%; padding:0 20px;
            }
        }
        > .comBtnGroup {padding:0 20px; margin-top:60px;}
    }
}


/*--------------------------------- 예금주 확인 로딩바 - 일치 불일치 --------------------------------*/ 
.cptCheckProcess {
    width: 100%;
    .loadingWrap {
        padding: 80px 0; text-align: center;
        .loadingBar {
            width: 100%; height: 7px; margin: 0 auto; border-radius: 3.5px;  background: #f3f3f3; box-shadow:  0 3px 7px 0 rgba(68, 68, 68, 0.04); overflow: hidden;
            .nowLoad {font-size: 0; height: 7px; border-radius: 3.5px; background: #005ce6; animation: loadbar 3s linear infinite;}

            @keyframes loadbar {
                from {width: 0;}
                to {width: 100%;}
            }
        }
        .loadingInfo {font-size: 16px; font-weight: 500; color: #fff; line-height: 1.625; margin-bottom:20px;}
    }
    .infoArea {
        border-top: 1px solid #dcdcdc; padding: 80px 0; text-align: center;
        &:first-child {margin-top: 80px;}
        .infoTit {
            font-size: 20px; font-weight: 500;
            &.t-red {color: #fe5f5f;}
            &.t-blue {color: #005ce6;}
        }
        .desc {margin-top: 20px; font-size: 16px; color: #444; line-height: 1.625;}
        .adds {margin-top: 40px; font-size: 14px; color: #666; line-height: 1.4;}
        .comBtnGroup {margin-top: 30px;}
    }
}



