/* -------------------------------- 일반 게시판 리스트 -------------------------------- */
.cptBoardList{
    width:100%; 

    .boardArea{
        width:100%; 

        .boardList{
            width:100%; margin-top:10px; padding:0 20px;

            .listGroup{
                .item{
                    width:100%; padding:30px 0; border-bottom:1px solid #dcdcdc; display:flex; position:relative;
                    .link{
                        width:100%;
                    }
                    .new{
                        width:29px; height:100%; min-height:24px; font-size:0; background:url(../../images/common/ico_new.svg) left top no-repeat; display:block;

                        & + .link{
                            width:calc(100% - 29px);
                        }
                    } 
                    .boardTitle{
                        width:max-content; max-width:100%; max-height:4.8rem; font-size:1.6rem; font-weight:400; line-height:1.5; color:#000; overflow: hidden;
                        -webkit-line-clamp: 2; -webkit-box-orient: vertical; display: -webkit-box; position:relative;
                        
                    }
                    .date{
                        margin-top:7px; font-size:1.4rem; font-weight:500; font-style:normal; line-height:1.5; color:#666; display:inline-block; width:max-content; position:relative; font-family:RedHatDisplay;
                    }

                    &.file{
                        .date{
                            &::after {content:''; position:absolute; width:1.8rem; height:1.8rem; top:50%; right:-2.5rem; transform:translateY(-50%); background: url(../../images/sub/ico_file.svg)50% 50% no-repeat;}
                        }
                    } 
                }
            }
        }

    }
}

/* -------------------------------- 게시판 상세 -------------------------------- */
.cptBoardView{
    width:100%; 

    .boardArea{
        width:100%; padding:0 20px;

        .boardTop{
            padding:0 10px 30px; border-bottom:1px solid #666;

            .title{
                font-size:1.8rem; font-weight:700; line-height:1.5; color:#000; display:block;
            }
            .date{
                margin-top:10px; font-size:1.4rem; line-height:1.8rem; color:#666; letter-spacing:0.028rem; font-family:"RedHatDisplay"; display:block;
            }
        }
        .boardCont{
            width:100%; padding:30px 10px; font-size:1.4rem; line-height:2; border-bottom:1px solid #dcdcdc;
        }
    }
}
/* -------------------------------- 자주하는 질문 -------------------------------- */
.cptAccordion{
    width:100%;

    .arrArea{
        width:100%;
    
        .accInner{
            width:100%; margin-top:7px; padding:0 20px;
            
            li{
                width: 100%; position: relative; text-align: left;
                button{
                    width: 100%; display: flex; flex-flow: row nowrap; align-items: flex-start; justify-content: space-between; padding: 30px 0; border-bottom: 1px solid #dcdcdc;
                    .qTxt{
                        width:calc(100% - 38px); display: flex; flex-flow: row nowrap; align-items: baseline; justify-content: flex-start;
                        .qMk{
                            display: inline-block; width: 30px; height: 30px; line-height: 30px; background: #4B9FF9; border-radius: 50%; text-align: center; 
                            font-family: "RedHatDisplay"; font-size: 18px; color: #fff; font-weight: bold; letter-spacing: -0.45px; margin-right: 10px;
                        }
                        .title{
                            width:calc(100% - 30px); font-size: 16px; color: #000; font-weight: 500; line-height: 24px; letter-spacing: -0.4px; text-align: left;
                        }
                    }
                    .accArr{
                        width:38px; padding-top:10px; text-align:right;
                        img{
                            transition: all 0.4s;
                            &.on{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .contBox{
                    display: none; width: 100%; background: #f2f9ff; border-bottom: 1px solid #dcdcdc; overflow: hidden;
                    p{
                        padding: 30px 10px; font-size: 1.4rem; color: #000; font-weight: 400; line-height: 2; letter-spacing: -0.4px; word-break: keep-all; display:block;
                    }
                }
            }
        }
    }
}
/* -------------------------------- 임직원 행동강령 아코디언 -------------------------------- */
/*
.cptAccorCont {
    @extend .cptAccordion;
    .accInner li button {padding: 30px 20px;}
    .accInner li .contBox {padding: 27px 20px;}

    & + & {margin-top: 64px;}
}
*/
/* -------------------------------- 썸네일-상세설명 목록 -------------------------------- */
/*
.cptGallDescList {
    width: 100%;

    .gallTable {
        .gallGroup {
            width: 100%; border-top: 1px solid #000;
            .gallItem {
                & > a {
                    width: 100%; padding: 40px 20px; border-bottom: 1px solid #dcdcdc; display: flex; align-items: center; column-gap: 30px;
                    .thumArea {
                        width: 213px; height: 120px; background: #f5f5f5;
                        img {width: 100%; height: 100%; object-fit: cover;}
                    }
                    .descArea {
                        width: calc(100% - 243px);
                        .gallTit {margin-bottom: 15px; font-size: 24px; color: #000; font-weight: 700; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
                        .gallDesc {font-size: 16px; color: #000; line-height: 2;  -webkit-line-clamp: 3; -webkit-box-orient: vertical; display: -webkit-box; overflow: hidden; word-break: keep-all;}
                    }
                }
            }
        }
    }
}
*/