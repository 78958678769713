// board


// contents
/*--------------------------------- 전자계약 리스트 --------------------------------*/
.cptContractList {
    .listArea {
        padding: 0 20px;
        .listAlert {
            width: 100%; margin: 60px 0 40px; text-align: center; word-break: keep-all;
            .alertTit {
                width: 100%; font-size: 2rem; color: #444; font-weight: 700; display: inline-block;
                b {color: #005ce6;}
            }
            i {margin-top: 20px; font-style: normal; font-size: 1.6rem; color: #666; display: inline-block;} 
        }

        .listGroup {
            .item {
                margin-bottom: 10px; border-radius: 25px; border: 1px solid #dcdcdc; padding: 30px;
                &:last-child {margin-bottom: 0;}
                .itemTit {
                    .num {font-size: 2rem; font-weight: 700; color: #000;}
                    .tit {font-size: 1.6rem; color: #444;}
                }
                .btnArea {
                    margin-top: 30px; text-align: center;
                    .contBtn {width: calc(100% - 80px); height: 50px; border-radius: 10px; font-size: 1.4rem; font-weight: 500; text-align: center; line-height: 49px; color: #fff; background: #444;}
                }
            }
        }
    }
}

/*--------------------------------- 서명 입력 캔버스 --------------------------------*/ 
.cptSignCanvas {
    .canvasArea {width: 100%; height: 200px; border-radius: 15px; border: 1px solid #dcdcdc; background: #f7f8fc;}
    .cnvsUI {
        margin-top: 10px; display: flex; align-items: center; justify-content: space-between;
        .info {font-size:1.2rem; font-weight: 500; color: #005ce6; font-weight: 500;}
        .cnvsRemove {padding-left: 24px; font-size: 1.4rem; color: #000; background: url(../../images/common/ico_reload.png) left center / 17px no-repeat;}
    }
}

/*--------------------------------- 저당설정 확인사항 --------------------------------*/ 
.cptConfirmRadio {
    margin-top: 40px; padding-top: 40px; border-top: 10px solid #f3f3f3;
    .radioArea {
        padding: 0 20px;
        .cfrmArea {
            margin: 30px 0 40px;
            .cfrmTit {font-size: 1.6rem; font-weight: 500; color: #000;}
            .cfrmDesc {margin: 10px 0 20px; font-size: 1.4rem; color: #444; line-height: 1.6; word-break: keep-all;}
            .detailBox {
                width: 100%; border-radius: 15px; border: 1px solid #dcdcdc; padding: 20px;
                .item {
                    margin-bottom: 20px;
                    &:last-child {margin-bottom: 0;}
                    .tit {display: block; font-size: 1.6rem; color: #444; font-weight: 700;}
                    .exp {display: block; margin-top: 10px; font-size: 1.4rem; color: #444; font-style: normal; word-break: keep-all;}
                }
            }
            .radioWrap {
                margin-top: 20px; text-align: center; position: relative;
                input[type=radio] + label {width: 100%; height: 50px; padding: 0 20px; border: 1px solid #ccc; border-radius: 10px; line-height: 49px; font-size: 1.4rem; font-weight: 500; color: #444; display: inline-block; cursor: pointer;}
                input[type=radio]:checked + label {border: 2px solid #005ce6; padding: 0 19px; line-height: 47px; color: #005ce6; font-weight: 500;}
            }
        }
    }
    
}

// common

/*--------------------------------- 안내사항 박스 --------------------------------*/
.comInfoBox {
    width: 100%; margin-bottom: 40px; padding: 40px 20px; text-align: center; border-radius: 15px; background: #f2f9ff;
    .infoTxt {
        padding-top: 65px; font-size: 1.4rem; color: #666; line-height: 1.6; background: url(../../images/common/ico_notice_round.png) center top / 45px no-repeat; word-break: keep-all;
        b {width: 100%; font-size: 1.6rem; font-weight: 500; color: #000;}
        i {margin-top: 20px;font-style: normal;  display: inline-block;}
    }
}

/* -------------------------------- 이미지 설명문(도표) -------------------------------- */
.comImgDesc {
    width: 100%; text-align: center;
    img {max-width: 100%;}
}