.cptForm{
    .formArea{
        padding:0 20px;
        .frmPlace{
            padding:50px 0 40px; position:relative;
            &::after{
                content:""; width:calc(100% + 40px); height:10px; background:#f3f3f3; display:block; position:absolute; top:0; left:-20px;
            }
            &:first-child{
                padding:0 0 40px;
                &::after{
                    display:none;
                }
            }
            &:last-child{
                padding:50px 0 0;
                &:first-child{
                    padding:0;
                }
            }
            
            & + .comBtnGroup{
                margin-top:0px;
            }
        }
        //------------------------------------ 단독 폼 ------------------------------------ 
        .flsTxt{ //잘못된 입력 및 필수입력 안내 메시지
            width:100%; margin-top: 10px; font-size: 1.2rem; color: #fe5f5f; position: relative; display:none; 
        }
        .desc{  //안내 메시지
            width:100%; margin-top: 10px; font-size: 1.2rem; color: var(--key-blue01); position: relative; display: inline-block; 
        }
        .frmTxt{
            .optTit{ //폼 옵션 타이틀
                font-size: 1.2rem; color: #999; font-weight: 700; line-height: 1.5; letter-spacing: -0.35px;

                .formalDataTxt {font-size:1.4rem; color:var(--key-blue01); font-weight:700; line-height:1.5; letter-spacing:-0.35px; display:inline-block; margin-left:1rem;}
            }
            .dirTitle{ //정보 메시지
                position: relative; display: inline-block; vertical-align: middle; padding-left: 27px; font-size: 1.4rem; color: #000; line-height: 1.5; letter-spacing: -0.45px;
                &::before{
                    content: ""; position: absolute; top: 50%; left: 0; transform: translateY(-50%); width: 20px; height: 20px; border-radius: 50%; 
                    background: #005ce6 url(../../images/common/ico_notice.svg) 50% 50% no-repeat;
                }
                &.check{
                    margin-top:40px;
                    &::before{background: #005ce6 url(../../images/common/ico_check_off.png) 50% 50% / 10px no-repeat;}
                }
                &.none{
                    padding-left:0; font-size:1.8rem; font-weight:500; color:#444;
                    &::before{display:none;}
                }
            }
        }
        .frmIpt{
            /*2024.07.26 추가*/
            &.ocw {
                // input {position:relative; z-index:2; background:transparent;}
                // .formalDataTxt {position:absolute; top:0; left:0; font-size:1.6rem; color:#ccc; font-weight:500; line-height:1.5; letter-spacing:-0.53px; padding-bottom:10px;}
            }
            margin-top:10px; display:flex; position: relative;

            & + .frmIpt{margin-top:20px;}
            .dataTxt{
                width: 100%; font-size: 1.6rem; font-weight: 500; color: #000; line-height: 1.5;
            }
            
            input{
                width:100%; padding:0 0 10px 0; font-size:1.6rem; color:#000; font-weight:500; line-height:1.5; letter-spacing: -0.53px; border:0; border-bottom: 2px solid #dcdcdc;

                &::-webkit-input-placeholder {font-size:1.6rem; color:#ccc; font-weight:500; line-height:1.5; letter-spacing:-0.45px;}
                &:focus {outline:0; border-bottom:2px solid #005ce6;}
            }
            .meas{
                width:3rem; padding:0 0 12px 0; font-size:1.6rem; color:#000; font-weight:500; line-height:1.5; text-align:right; letter-spacing: -0.53px; 
                position:absolute; right:0; bottom:0;
            }
            .iptBtn{
                height:29px; padding:0 12px; font-size: 1.2rem; color: #fff; font-weight: 400; line-height: 27px; letter-spacing: -0.35px; background: #666;  
                box-sizing: border-box; position: absolute; bottom: 12px; right: 0; border-radius: 5px;
            }
            &.inText1{ //우측 끝에 한글자 만큼 영역 차지하고 우측 정렬
                input {padding-right:3rem; text-align:right;}
            }
            &.inText2{ //우측 끝에 두글자 만큼 영역 차지하고 우측 정렬
                input {padding-right:4.5rem; text-align:right;}
                .meas{width:4.5rem;}
            }
            &.inBtn{ //우측 끝에 버튼이 존재하고 입력 영역은 그만큼 줄어듬
                input {}
                .btnWrap{
                    margin-left: 10px; position:static; display: flex; column-gap: 3px;
                    .iptBtn{
                        width: max-content; position:static;
                    }
                }

            }
            .dash{
                width:15px; min-width:15px; padding-bottom:12px; font-size:1.6rem; line-height:1.87; text-align:center;
            }
            .resetBtn {
                display: none; width: 27px; height: 27px; background: url(../../images/common/ico_reset.svg) center center no-repeat; 
                border-radius: 50%; position: absolute; top: 50%; right: 0; transform: translateY(-50%);
            }

            .designSelect{
                width:100%; height:60px; padding:0 20px; font-size:1.6rem; line-height:58px; font-weight:400; color:#000; text-align:left; border:1px solid #bebebe; 
                border-radius:13px; position:relative;
                &::after{
                    content:""; width:18px; height:18px; background:url(../../images/common/ico_privacy_down.svg) center no-repeat; display:block; position:absolute; right:20px; top:20px;
                }
            }

            .halfWrap { //영역을 절반으로 줄임
                width: calc(50% - 7.5px); margin-right: 12px; position: relative; display: inline-block;
                &:last-child {margin-right: 0;}
            }
        }
        .frmRdo {
            position:relative; display:inline-block; vertical-align:middle;
    
            input {
                position:absolute; top:0; left:0; width:100%; height:100%; border:0; appearance:none; -webkit-appearance: none; cursor:pointer; padding:0;
    
                + label {
                    width:100%; height:100%; min-height:45px; padding: 9px 13px; font-size:1.4rem; font-weight:400; color:#444; text-align:center; line-height:1.5; border:1px solid #ccc; border-radius:10px; 
                    display:flex; justify-content: center; align-items: center; position:relative; box-sizing:border-box; cursor:pointer; 
                }
    
                &:checked {
                    + label {
                        border:2px solid #005ce6; color:#005ce6; font-weight:500;
                    }
                }
            }
    
            &:last-of-type {margin-right:0;}
            
            &.lg {
                input {
                    + label {
                        min-height:6rem; padding: 7px 13px; border-radius:13px; 
                    }
                }
            }
        }

        //------------------------------------  리스트 폼 ------------------------------------ 
        //한줄짜리 리스트
        .frmList{
            width: 100%; margin-top: 40px;
            .listGroup{
                > .item{
                    margin-top: 40px;
                    &:first-child{margin-top:0;}
                }
            }
        }
        
        //라디오 그룹 폼
        .radioWrap{
            width:100%; display:flex; gap:10px; margin-top:10px;

            & + .frmIpt{
                margin-top:30px;
            }
            .frmRdo{
                width:100%; display:block;
            }
            &.multi{
                flex-wrap: wrap;
            }
            &.half{
                .frmRdo{
                    width:calc(50% - 5px);
                }
            }
            &.third{
                .frmRdo{
                    width:calc(33% - 7px);
                }
            }
        }

        .frmBtnGroup {
            margin-top: 10px;
            .btnArea {
                display: flex; column-gap: 5px;
                &.left {justify-content: flex-start;}
                .option{width:calc(20% - 4px) !important; padding:0 10px;}
            }
        }

        /*validation*/
        .error {
            input[type='text'],
            input[type='password'] {border-bottom:2px solid #fe5f5f; color:#fe5f5f;}
            input[type='radio'] + label {border:2px solid #fe5f5f; color:#fe5f5f;}
            input[type='checkbox'] + label  > * {color:#fe5f5f !important;}
            &.frmCombo {
                border:1px solid #fe5f5f;

                button {color:#fe5f5f;}
                .drpdwBox {border:1px solid #fe5f5f; border-top:0;}
            }
            // .dash {background:#fe5f5f !important;}
            
            .optTit {color:#fe5f5f !important;}
            .designSelect {border:2px solid #fe5f5f;}
            .flsTxt {display:block;}
            .meas {color:#fe5f5f !important;}
        }

    }
}

.topBar{
    margin-top:40px; padding-top:50px; position:relative;
    &::before{
        content:""; width:100vw; height:10px; background:#f3f3f3; display:block; position:absolute; left:0; top:0;
    }
}

//------------------------------------ 토스트팝업 메시지 ------------------------------------
.comMsg {
    width:100%; text-align:center;
    .strMsg {
        margin-top:10px; font-size:1.6rem; line-height:1.5; font-weight:500; color:#000;

        &:first-child{
            margin-top:0;
        }
        b{
            font-weight:700;
        }
    }
    .baseMsg {
        margin-top:10px; font-size:1.6rem; line-height:1.5; font-weight:400; color:#000;

        &:first-child{
            margin-top:0;
        }
        b{
            font-weight:500;
        }
    }
    & + .comBtnGroup{
        margin-top:40px;
    }
}
// ------------------------------------ 대출심사현황 > 각종서류 제출 ------------------------------------
.cptSubmitDoc{
    width:100%;
    .docuArea{
        width:100%; padding:0 20px;

        .cardBtnGroup{
            .cardGroup{
                .cardItem{
                    width:100%; position:relative;
                    
                    > input{position:absolute; left:0; top:0;
                        &:checked + .card{
                            padding:29px; border:2px solid #bfdfff; background:#f2f9ff;
                        }
                    }
                    .card{
                        width:100%; padding:30px; margin-top:10px; border:1px solid #dcdcdc; border-radius:25px; display:block;

                        .tit{
                            font-size:2rem; font-weight:700; color:#000;
                        }
                        .infoGroup{
                            margin-top:10px;
                            .info{
                                width:100%; font-size:1.6rem; font-weight:400; color:#444;
                                b{font-weight:400;}
                                i{margin-left:7px; font-style:normal;}
                            }
                        }
                    }
                }
            }
        }
        .fileArea{
            margin-top:40px; padding-top:50px; position:relative;
            &::before{
                content:""; width:100vw; height:10px; background:#f3f3f3; display:block; position:absolute; left:-20px; top:0;
            }
            .fileList{
                margin-top: 20px; display: flex; flex-wrap: wrap; gap: 7px;
            }
            .imgWrap{
                width: 107px; height: 107px; border: 1px solid #dcdcdc; border-radius: 15px; position: relative; text-align: center; overflow: hidden;

                img{
                    border: 8px solid #fff; overflow: hidden; width: 100%; height: 100%; object-fit: cover; border-radius: 15px;
                    &.noFile {border:none;}
                }
                .btnRemove{
                    width: 20px; height: 20px; font-size: 0; background: url(../../images/common/ico_reset_circle.svg) center no-repeat; position: absolute; top: 10px; right: 10px;
                }
            }
            
        }

    }
}

// ------------------------------------ 팝업 일반 텍스트 ------------------------------------
.comClause{
    .basicTxt{
        font-size:1.4rem; font-weight:400; color:#000;
    }
}
