/*대출신청 여백 설정*/
.checkAllBox {margin-bottom:2.4rem; margin-top:4rem;}
.checkList ul li {margin-bottom:2.5rem;}
.formTopArea {
    padding:3rem 0 4rem 0; border-bottom:10px solid #f3f3f3;

    &.noUnderLine {border-bottom:0;}
}
.formBotArea {
    padding:4rem 0 4rem 0;
            
    & + .comBtnGroup{
        margin-top:0px; margin-bottom:60px;
    }
}

/*팝업*/
.modFlPop {
    display:none; position:relative; z-index:999;

    .popCont {
        position:fixed; top:0; left:0; width:100%; height:100%; background:#fff;

        .titArea {
            padding:30px 20px; display:flex; flex-flow: row nowrap; align-items: center; justify-content: space-between;

            > p {font-size:1.8rem; color:#000; font-weight:bold; line-height:1.5;}

            > button {width:2rem; height:2rem; background:url("../../images/common/ico_pop_cls.svg")50% 50% no-repeat;}
        }

        .popContArea {
            width:100%; height:calc(100% - 21.3rem); overflow-y: scroll; padding:0 20px 30px;

            .txtInnCov {
                width:calc(100% - 3.4rem); font-size: 1.4rem; color:#000; font-weight: 400; line-height:1.5
            }

            .clsBtn {width:2rem; height:2rem; background: url("../../images/common/ico_pop_cls.svg")50% 50% no-repeat; }
        }
        .cptLoanFloat {
            height:126px;
            .floatBox{
                height:106px;
                .btnArea{
                    .loanBtn{
                        height:50px; line-height:50px;
                    }
                }
            }
        }
    }


}

.loadingCov {
    position:fixed; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.5); z-index:999;

    .ldBar {
        position:absolute; width:70%; top:50%; left:50%; transform: translate(-50%,-50%);
    }
}

.processComplete {
    width:100%; padding:140px 20px 0 20px;
    .check {
        text-align: center;
        .tit {font-size:24px; color:#000; font-weight:500; line-height:32px; letter-spacing: -0.6px; margin-top:15px;}
        .desc {
            font-size:16px; color:#666; font-weight:400; line-height:26px; letter-spacing: -0.4px; margin-top:30px;
            &.ty02 {font-size:14px; color:#999; font-weight:500;}
        }
    }
    .loanInfo {
        width:100%; border:1px solid #dcdcdc; border-radius:15px; padding:0 20px 40px 20px; margin-top:40px;

        .item {padding-bottom:20px; margin-top:20px !important; border-bottom:1px solid #dddddd;}
        .item:last-of-type {border:0; padding-bottom:0;}
    }
}

/*2024.05.16 약관 html 파일 css*/
.startTerms {
    width:100%; position:relative; font-size:1.6rem; color:#444; line-height:2.4rem; letter-spacing:-0.4px;

    > .title {
        font-size:1.8rem; font-weight:bold;

        &.head {font-size:2.4rem; text-align:center;}
    }
    > .title:not(:first-of-type) {margin-top:4rem;}

    > .description {
        margin-top:1rem;
        &.small {
            font-size:1.4rem; color:#666;
        }

        &.head {font-size:1.6rem; text-align:center;}
    }

    .list {
        width:calc(100% - 2rem); position:relative; margin-left:1rem; margin-top:1rem;

        > ul {
            width:100%;
            > li {
                width:100%; margin-top:1rem;

                &:first-of-type {margin-top:0;}

                div {
                    ul {
                        li {
                            width:100%; margin-top:.5rem;

                            &:first-of-type {margin-top:0;}
                        }
                    }
                }
            }
        }
    }

    .tableBox {
        width:100%; margin-top:1rem; overflow-x: scroll;
        table {
            width:650px; border-collapse: collapse; 
            
            thead {
                tr {
                    th{
                        background:#dcdcdc; text-align:center; padding: 1rem 1rem; border:1px solid #ccc; font-size:1.6rem; color:#444; font-weight:500;
                    }
                }
            }
            
            tbody {
                tr {
                    td { 
                        background:#fff; text-align:left; padding: 1rem 1rem; border:1px solid #dfdfdf; font-size:1.6rem; color:#444; font-weight:400;
                    }
                }
            }
        }

    }
}



/*2024.05.28 작성안내 가이드 페이지 추가*/
.infoGdTab {
    padding: 0 2rem;
    .infoGuide {
        width:100%; margin-top:3rem;
        .infoCapture {
            width:100%; text-align:center;
    
            img {display:inline-block;}
        }
    
        .divLine {display:block; width:100%; height:1px; background:#dcdcdc; margin:6rem 0;}
    
        .infoTxtBox {
            width:100%; position:relative; padding:3rem; background:#f8f8f8; margin-top:2rem; border-radius:1.5rem;
    
            .leftArea {
                span.num {display:block; width:3rem; height:3rem; border-radius:50%; background:#005ce6; font-size:1.6rem; color:#fff; text-align:center; font-weight:500; line-height:3rem; margin-bottom:1.5rem}
            }
            .rightArea {
                p.title {
                    font-size:1.6rem; color:#000; font-weight:400; line-height:2.6rem; letter-spacing:-0.45px; word-break:keep-all;
    
                    > span {color:#005ce6; font-weight:500;}
                }
                p.desc {font-size:1.4rem; color:#000; font-weight:400; line-height:2.4rem; letter-spacing:-0.4px; margin-top:2rem; word-break:keep-all;}
            }
    
        }
    }
}


/*2024.05.28 작성안내 가이드 페이지 추가*/
.infoGdTab {
    padding: 0 2rem;
    .infoGuide {
        width:100%; margin-top:3rem;
        .infoCapture {
            width:100%; text-align:center;
    
            img {display:inline-block;}
        }
    
        .divLine {display:block; width:100%; height:1px; background:#dcdcdc; margin:6rem 0;}
    
        .infoTxtBox {
            width:100%; position:relative; padding:3rem; background:#f8f8f8; margin-top:2rem; border-radius:1.5rem;
    
            .leftArea {
                span.num {display:block; width:3rem; height:3rem; border-radius:50%; background:#005ce6; font-size:1.6rem; color:#fff; text-align:center; font-weight:500; line-height:3rem; margin-bottom:1.5rem}
            }
            .rightArea {
                p.title {
                    font-size:1.6rem; color:#000; font-weight:400; line-height:2.6rem; letter-spacing:-0.45px; word-break:keep-all;
    
                    > span {color:#005ce6; font-weight:500;}
                }
                p.desc {font-size:1.4rem; color:#000; font-weight:400; line-height:2.4rem; letter-spacing:-0.4px; margin-top:2rem; word-break:keep-all;}
            }
    
        }
    }
}

/*2024.06.25 직장검색 새창 팝업 추가*/
.cptSearchJob {
    width:100%; position:relative;

    >.title {padding:2rem; font-size:2rem; color:#000; font-weight:500; line-height:1; letter-spacing:-0.5px; border-bottom:1px solid #dcdcdc;}

    .searchList {
        padding: 3rem 2rem 0 2rem; height:52rem; overflow-y:scroll;
        
        >.listTit {font-size:16px; color:#999; font-weight:400; line-height:1; letter-spacing:-0.4px; margin-bottom:1rem;}

        > ul {
            > li {
                &.noList {width:100%; font-size:1.4rem; font-weight:bold; text-align:center; line-height:1; padding: 5rem 0;}
                > a {
                    display:block; width:100%; padding: 3rem; position:relative; background:#f3f3f3; border-radius:1.5rem; margin-bottom:1rem;

                    > .jobListTit {display:block; font-size:1.4rem; color:#000; font-weight:bold; line-height:1; letter-spacing:-0.35px; margin-bottom:.5rem;}
                    > .jobListDesc {
                        display:block; font-size:1.4rem; color:#000; font-weight:400; line-height:2.4rem; letter-spacing:-0.35px; margin-bottom:1.5rem;
                        &:last-of-type {margin-bottom:0;}
                    }
                }
                &:last-of-type {
                    a {margin-bottom:0;}
                }
            }
        }
    }

    .comBtnGroup {padding: 0 2rem;}
}

/*2024.07.30 신분증 가이드 및 안내 추가*/
.flsArea {
    width:100%; background:#f7f9fc; border-radius:1.5rem; padding:2rem; text-align:center; margin-top:2rem;
    .flsTit {font-size:1.6rem; color:#f35f5f; line-height:2.2rem; letter-spacing:-0.4px; font-weight:500; margin-bottom:1rem;}
    .flsDesc {font-size:1.3rem; color:#999; line-height:2rem; letter-spacing:-0.33px; font-weight:400;}
}

.licenseGuide {
    width:100%; background:#f7f9fc; border-radius:1.5rem; padding:2rem; text-align:center; margin-top:2rem;

    .licTit {font-size:1.6rem; line-height:2.2rem; letter-spacing:-0.4px; font-weight:500; margin-bottom:1rem; color:var(--key-blue01);}
    .licDesc {font-size:1.3rem; color:#444; line-height:2rem; letter-spacing:-0.33px; font-weight:400;}

}

/*2024.07.30 신분증 가이드 팝업*/
.uploadGuideBox {
    margin-bottom:4rem; 
    p.positive {
        padding-left:2.7rem; font-size:1.8rem; color:#000; font-weight:500; line-height:3.2rem; letter-spacing:-1.2px; position:relative; margin-bottom:2rem;
        &::before {content:''; position:absolute; top: 50%; left:0; width:2rem; height:2rem; background:url(../../images/sub/ico_guide_true.png)center left no-repeat; transform:translateY(-50%);}
    }

    p.negative {
        padding-left:2.7rem; font-size:1.8rem; color:#000; font-weight:500; line-height:3.2rem; letter-spacing:-1.2px; position:relative; margin-bottom:2rem; margin-top:4rem;
        &::before {content:''; position:absolute; top: 50%; left:0; width:2rem; height:2rem; background:url(../../images/sub/ico_guide_false.png)center left no-repeat; transform:translateY(-50%);}
    }

    .guideImgBox {background:#f3f3f3; border-radius:25px; padding:2.6rem; text-align:center;}
}
