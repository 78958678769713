/* ---------------------------------------- 기본 레이아웃 ---------------------------------------- */
.lytLayout{
    width:100%;

    .layoutArea {
        width:100%;

        .layoutTop{
            width:100%;
        }

        .layoutContainer {
            .layoutMainCont{
                width:100%;
            }
            .layoutSubVisual{
                width:100%;
            }
            .layoutSubCont{
                width:100%;
            }
            .subContArea{
                width:100%;
            }
            .layoutCont{
                width:100%;
            }
            .layoutFloat {width: 100%;}
        }
        .layoutFooter{
            width:100%;
        }
    }
}

/*--------------------------------- 레이어팝업 --------------------------------*/
.modPopup{
    &.full{
        width:100vw; height:100dvh; position:fixed; left:0; top:0; background:#fff; z-index:1000;

        &.popOpen{
            display:block;
        }

        .popupBox{
            width:100%; height:100%;
        }
        .popTitArea{
            width:100%; height:70px; padding-left:20px; background:#fff; display:flex; position:fixed; left:0; top:0;
            .tit{
                width:calc(100% - 60px); height: 100%; padding-left: 27px; font-size: 2rem; font-weight: 500; color: #000; line-height: 70px; display: inline-block; position: relative;

                &::after{
                    content: ""; width: 12px; height: 12px; background: url(../../images/common/ico_gnb_back.svg) center no-repeat; 
                    display: block; position: absolute; left: 0; top: 30px;
                }
            }
            .closeBtn{
                width:60px; height:100%; font-size:0; background:url(../../images/common/ico_close.svg) center no-repeat;
            }
        }
        .popContArea{
            height:100%; padding-top:94px; padding-bottom:60px; overflow-y: auto;

        }
    }
}



/*--------------------------------- 토스트팝업 --------------------------------*/
.modTostPop {
    width:100%; height:150vh; display:none; position:fixed; left:0; bottom:0; z-index:1000;
    .popupDim {
        width:100%; height:100%; background:rgba(0,0,0,0.5); font-size: 0; position:fixed; left:0; bottom:0; z-index:1000; animation: 0.3s hideDim; animation-fill-mode:forwards;
    }
    .popupArea{
        width:100%; height:auto; max-height:calc(100dvh - 20px); display:flex; justify-content: center; position:absolute; left:0; bottom:-100%; z-index:1001; animation: 0.5s downPop; animation-fill-mode:forwards;
        .popupBox{
            width:100%; max-width:600px; height:100%; max-height: calc(100dvh - 20px); padding:20px; border-radius:25px 25px 0 0; background:#fff;;

            .popTitArea{
                width:100%; padding:20px 0;  position:relative;
                .tit{
                    height:27px; font-size:1.8rem; font-weight:500; line-height:1.5; color:#000;
                }
                .closeBtn{
                    width:30px; height:30px; font-size:0; background:url(../../images/common/ico_20_close.svg) center no-repeat; position:absolute; top:-5px; right:-5px;
                }
            }
            .popContArea{
                width:100%; height:calc(100% - 67px); max-height: calc(100dvh - 127px); overflow-y:auto;

                /*-------------------- 은행선택 내용 --------------------*/
                .bankTit {
                    font-size:18px; color:#000; font-weight: 500; line-height:1; letter-spacing:-0.45px; margin-bottom:20px;
                }
                .bankListCov{
                    display:flex; flex-flow: row wrap; align-items: center; justify-content: flex-start; gap:20px; 
                    > li {
                        width:calc(50% - 20px); display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start;
                        > img {display:inline-block; margin-right:10px}
                        > span {font-size:14px; color:#000; font-weight:400; line-height:1.71; letter-spacing: -0.35px;}
                    }
                }
                /*-------------------- // 은행선택 내용 --------------------*/

                /*-------------------- 예금주 일치&불일치 내용 --------------------*/
                .accChkBox {
                    .tit {font-size:18px; font-weight:bold; line-height:2.33; letter-spacing: -0.45px; margin-bottom:20px; text-align: center; padding-top:20px;}
                    .desc {font-size:14px; color:#000; font-weight:400; line-height: 1.57; letter-spacing: -0.35px; margin-bottom:20px; text-align: center;}
                    
                    > .actCorr {
                        .tit {color:#005ce6;}
                    }

                    > .actInCorr {
                        .tit {color:#fe5f5f;}
                        .desc {
                            margin-bottom:10px;
                            &.ty02 {
                                font-size:12px; color:#999; font-weight:400; line-height:1.5; margin-bottom:20px; text-align:center;
                            }
                        }
                    }
                }
                /*-------------------- //예금주 일치&불일치 내용 --------------------*/
            }
            .popBtnArea{
                width:100%; height:90px; padding:20px 0;
                .popBtn{width:100%; height:50px; line-height:50px; font-size:14px; color:#fff; font-weight:500; text-align: center; border-radius: 10px;}
                .blue{background:#005ce6; }
            }
        }
    }
    &.popOpen{
        bottom:0;
        .popupDim {
            animation: 0.3s showDim; animation-fill-mode:forwards;
        }
        .popupArea{
            animation: 0.5s upPop; animation-fill-mode:forwards;
        }
    }
}
@keyframes showDim {
    0% { opacity:0; visibility: block;}
    100% { opacity:1;  }
}

@keyframes hideDim {
    0% { opacity:1; }
    100% { opacity:0; visibility: hidden;  }
}
@keyframes upPop {
    0% { bottom:-100%;}
    100% { bottom: 0;  }
}
@keyframes downPop {
    0% { bottom:0; }
    100% { bottom:-100%; }
}


/* -------------------------------- 탭 -------------------------------- */
.modTab{
    margin-top:20px;

    &:first-child{margin-top:0;}
    .tabBtn{
        width: 100%; display: flex; background:#f3f3f3; border-radius:2.7rem; overflow:hidden;
        li{
            width: 50%; height: 56px;  position: relative;

            button{
                width: 100%; height: 100%; font-size: 1.4rem; font-weight: 500; color: #999; border: 1px solid #f3f3f3; background:#f3f3f3; border-radius:2.7rem;
            }
            &.active{
                z-index: 1; border: none;
                button{
                    color: #fff; border: 1px solid #005ce6; background:#005ce6;
                }
            }
        }
    }
}
